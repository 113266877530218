import styles from "./private-networking.module.scss";

import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import ReactCountryFlag from "react-country-flag";
import IpSubnetCalculator from "ip-subnet-calculator";
import { useAjax, usePrompt, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { CountrySelector, CustomReactSelect } from "@dreamvps/dream-ui/dist";
import { SUPER_ADMIN } from "@dreamvps/dream-utils/dist/user";
import { validateIPaddress } from "@dreamvps/dream-utils/dist/networks";
import { normalizeLanIp } from "../../utils/networks";

function PrivateNetworking({
  vlans,
  setVlans,
  location,
  setLocation,
  vlanName,
  setVlanName,
  ipScope,
  setIpScope,
  ipAddress,
  setIpAddress,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  server,
  forUserID,
  gatewayOptions,
  setGatewayOptions,

  staticLocation,
  staticVlanName,
  staticVlanID,
  hideIpAddressSelect = false,
}) {
  const ajax = useAjax();
  const user = useUser();
  const serverRef = useRef(server);

  const intl = useIntl();
  const intlRef = useRef(intl);
  const prompt = usePrompt();
  const promptRef = useRef(prompt);

  const [ipAddressOptions, setIpAddressOptions] = useState([]);
  const [overrideGateway, setOverrideGateway] = useState(false);

  const [lanNameSelectKey, setLanNameSelectKey] = useState(0);
  const [newLanName, setNewLanName] = useState(null);
  const [customScope, setCustomScope] = useState(null);

  const netmaskOptions = useMemo(() => {
    if (!vlans || !vlanName || vlanName.value === "") {
      return [];
    }

    const vlanObj = vlans.find((item) => item._id === vlanName.value);

    if ((vlanName && vlanName.__isNew__) || !vlanObj.subnets[0]) {
      return [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((number) => ({
        value: number,
        label: number,
      }));
    }

    return [
      { value: vlanObj.subnets[0].netmask, label: vlanObj.subnets[0].netmask },
    ];
  }, [vlanName, vlans]);

  const userIDToWork = useMemo(() => {
    if (user.role === SUPER_ADMIN) {
      if (forUserID) {
        return forUserID;
      } else {
        return "ALL";
      }
    } else {
      return serverRef.current ? serverRef.current.user_id : user._id;
    }
  }, [forUserID, user._id, user.role]);

  useEffect(() => {
    if (netmaskOptions[4]) {
      setNetmask(netmaskOptions[4]);
    } else {
      setNetmask(netmaskOptions[0]);
    }
  }, [netmaskOptions, setNetmask]);

  const getVLANs = useCallback(async () => {
    const data = await ajax("/network/getVLANs", {
      userIDToWork,
      location,
      type: "lan",
    });

    setVlans(data.vlans);
  }, [ajax, location, setVlans, userIDToWork]);

  useEffect(() => {
    getVLANs();
  }, [getVLANs]);

  useEffect(() => {
    setIpScope(null);
    setNetmask(null);
    setIpAddress(null);
  }, [vlanName, setIpAddress, setNetmask, setIpScope]);

  useEffect(() => {
    setIpAddress(null);
  }, [netmask, setIpAddress]);

  useEffect(() => {
    if (
      !vlans ||
      (!hideIpAddressSelect && !ipAddress) ||
      !netmask ||
      !vlanName
    ) {
      return;
    }

    const gateways = [
      { value: "", label: intl.formatMessage({ id: "general.none" }) },
    ];

    const vlanObj = vlans.find((item) => item._id === vlanName.value);

    if (
      hideIpAddressSelect ||
      vlanName.__isNew__ ||
      (vlanObj && !vlanObj.subnets[0])
    ) {
      const normalizeIp = normalizeLanIp(
        hideIpAddressSelect ? ipScope.value : ipAddress.value
      );

      const ipSubnetCalculator = IpSubnetCalculator.calculateSubnetMask(
        normalizeIp,
        netmask.value
      );

      for (
        let i = ipSubnetCalculator.ipLow + 1;
        i <= ipSubnetCalculator.ipHigh;
        i++
      ) {
        const ip = IpSubnetCalculator.toString(i);

        if (ip !== ipAddress?.value) {
          gateways.push({ label: ip, value: ip });
        }
      }
    } else {
      if (vlanObj) {
        gateways.push({
          label: vlanObj.subnets[0].gateway,
          value: vlanObj.subnets[0].gateway,
        });
      }
    }

    setGatewayOptions(gateways);
  }, [
    ipAddress,
    ipScope,
    setGatewayOptions,
    intl,
    netmask,
    vlanName,
    hideIpAddressSelect,
    vlans,
  ]);

  const lansOptions = useMemo(() => {
    const options = [
      {
        value: "",
        label: intl.formatMessage({ id: "private-networking.create-new-lan" }),
      },
    ];

    if (vlans) {
      options.push(
        ...vlans.map((vlan) => ({
          value: vlan._id,
          label: vlan.vlanName,
        }))
      );
    }

    if (newLanName) {
      options.push(newLanName);
    }

    return options;
  }, [intl, vlans, newLanName]);

  useEffect(() => {
    async function showPrompt() {
      let newName = await promptRef.current({
        title: intlRef.current.formatMessage({
          id: "private-networking.new-vlan.title",
        }),
        message: intlRef.current.formatMessage({
          id: "private-networking.new-vlan.message",
        }),
      });

      newName = newName?.trim();

      if (newName) {
        const newLanOption = {
          label: newName,
          value: newName,
          __isNew__: true,
        };

        setNewLanName(newLanOption);
        setVlanName(newLanOption);
      } else {
        setVlanName(null);
        setLanNameSelectKey(lanNameSelectKey + 1);
      }
    }

    if (vlanName?.value === "") {
      showPrompt();
    }
  }, [lanNameSelectKey, setVlanName, vlanName]);

  useEffect(() => {
    async function fetch() {
      const params = {
        userIDToWork: userIDToWork === "ALL" ? user._id : userIDToWork,
        ipScope: ipScope.value,
        netmask: netmask.value,
      };

      if (staticLocation) {
        params.location = staticLocation;
      } else {
        if (!location) {
          return;
        }

        params.location =
          typeof location === "object" ? location.value : location;
      }

      const data = await ajax("/network/getFreeLanIps", params);

      if (!vlanName.__isNew__) {
        const vlanObj = vlans.find((item) => item._id === vlanName.value);

        if (vlanObj?.subnets[0]) {
          data.ips = data.ips.filter((ip) => ip !== vlanObj.subnets[0].gateway);
        }
      }

      setIpAddressOptions(data.ips.map((ip) => ({ label: ip, value: ip })));
    }

    if (vlanName && ipScope && ipScope.value === -1) {
      promptRef.current({
        title: intlRef.current.formatMessage({
          id: "private-networking.custom-ip-scope.title",
        }),
        message: intlRef.current.formatMessage({
          id: "private-networking.custom-ip-scope.message",
        }),
        acceptOnlyValue: (ip) => {
          if (!validateIPaddress(ip)) {
            return {
              status: false,
              reason: intlRef.current.formatMessage({
                id: "private-networking.custom-ip-scope.error.wrong-ip",
              }),
            };
          }

          if (!normalizeLanIp(ip)) {
            return {
              status: false,
              reason: intlRef.current.formatMessage({
                id: "private-networking.custom-ip-scope.error.wrong-scope",
              }),
            };
          }

          return { status: true };
        },
        beforeClose: (ip) => {
          const newCustomScope = { label: ip, value: ip };
          setCustomScope(newCustomScope);
          setIpScope(newCustomScope);
        },
      });
    } else if (vlanName && ipScope && netmask) {
      fetch();
    }
  }, [
    ajax,
    location,
    netmask,
    staticLocation,
    user._id,
    userIDToWork,
    ipScope,
    vlanName,
    vlans,
    setIpScope,
  ]);

  const getConfig = useCallback(async () => {
    if (!server) {
      return;
    }

    setOverrideGateway(false);

    const data = await ajax("/proxmox/nodes/qemu/getConfig", {
      serverID: server._id,
    });

    const gateways = Object.keys(data.data).filter((key) =>
      data.data[key].toString().includes("gw=")
    );

    if (gateways.length >= 1) {
      setOverrideGateway(true);
    }
  }, [ajax, server]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  useEffect(() => {
    if (staticVlanName) {
      setVlanName({ label: staticVlanName, value: staticVlanID });
    }
  }, [setVlanName, staticVlanName, staticVlanID]);

  function handleLanNameChanged(item) {
    setVlanName(item);
  }

  const ipScopeOptions = useMemo(() => {
    let scopes = [];

    if (vlanName && !vlanName.__isNew__) {
      const vlanObj = vlans.find((item) => item._id === vlanName.value);

      if (vlanObj && vlanObj.subnets[0]) {
        const nip = normalizeLanIp(vlanObj.subnets[0].ipAddress);

        scopes.push({
          label: nip,
          value: nip,
        });
      }
    }

    if (scopes.length === 0) {
      scopes.push(
        {
          label: "172.16.0.0",
          value: "172.16.0.0",
        },
        {
          label: "192.168.0.0",
          value: "192.168.0.0",
        },
        {
          label: "10.0.0.0",
          value: "10.0.0.0",
        },
        {
          label: intlRef.current.formatMessage({ id: "general.custom" }),
          value: -1,
        }
      );
    }

    if (customScope) {
      scopes.unshift(customScope);
    }

    return scopes;
  }, [vlanName, vlans, customScope]);

  function renderLanNameSelect() {
    return (
      <CustomReactSelect
        key={lanNameSelectKey}
        placeholder={intl.formatMessage({
          id: "general.select...",
        })}
        value={vlanName}
        onChange={handleLanNameChanged}
        options={lansOptions}
      />
    );
  }

  function renderLanNameSection() {
    if (!server) {
      return (
        <>
          <div className="drow f100">
            <span>
              <FormattedMessage id="add-ip-address-scope-modal.location" />
            </span>
            {staticLocation && (
              <div>
                <ReactCountryFlag
                  countryCode={staticLocation}
                  style={{ fontSize: "40px" }}
                />
                <b>{staticLocation}</b>
              </div>
            )}
            {!staticLocation && (
              <CountrySelector
                value={location}
                onChange={(country) => setLocation(country)}
                countriesFilter={["IL", "NL", "US"]}
              />
            )}
          </div>

          {(location || staticVlanName) && (
            <div className="drow f100">
              <span>
                <FormattedMessage id="private-networking.lan-name" />
              </span>
              {staticVlanName && <div>{staticVlanName}</div>}
              {!staticVlanName && renderLanNameSelect()}
            </div>
          )}
        </>
      );
    }

    return (
      <div className="drow f100">
        <span>
          <FormattedMessage id="private-networking.lan-name" />
        </span>
        {renderLanNameSelect()}
      </div>
    );
  }

  function renderIpScopeSection() {
    if (!vlanName) {
      return null;
    }

    return (
      <div className={`drow f100 ${styles.ipAddress}`}>
        <span className={styles.text}>
          <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
        </span>
        <CustomReactSelect
          options={ipScopeOptions}
          value={ipScope}
          onChange={(item) => setIpScope(item)}
        />
        <span className={styles.seperator}>/</span>
        <CustomReactSelect
          className={styles.netmask}
          placeholder={intl.formatMessage({ id: "general.netmask" })}
          options={netmaskOptions}
          value={netmask}
          onChange={(item) => setNetmask(item)}
        />
      </div>
    );
  }

  function renderIpAddressSection() {
    if (
      hideIpAddressSelect ||
      !vlans ||
      !vlanName ||
      vlanName.value === "" ||
      !ipScope ||
      !netmask
    ) {
      return null;
    }

    return (
      <>
        <div className={`drow f100 ${styles.ipAddress}`}>
          <span className={styles.text}>
            <FormattedMessage id="private-networking.ip-address" />
          </span>
          <CustomReactSelect
            placeholder={intl.formatMessage({
              id: "general.select...",
            })}
            options={ipAddressOptions}
            value={ipAddress}
            onChange={(item) => setIpAddress(item)}
          />
        </div>
      </>
    );
  }

  function renderGatewaySection() {
    if (!vlanName || (!hideIpAddressSelect && !ipAddress) || !netmask) {
      return null;
    }

    return (
      <>
        <div className="drow f100">
          <span>
            <FormattedMessage id="private-networking.gateway" />
          </span>
          <CustomReactSelect
            options={gatewayOptions}
            value={gateway}
            onChange={(item) => setGateway(item)}
          />
        </div>

        {overrideGateway && gateway && gateway.value && (
          <div className="warning">
            <FormattedMessage id="private-networking.gateway-override" />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.privateNetworkingWrapper}
    >
      {renderLanNameSection()}

      {renderIpScopeSection()}

      {renderIpAddressSection()}

      {renderGatewaySection()}
    </div>
  );
}

PrivateNetworking.propTypes = {
  vlans: PropTypes.array,
  setVlans: PropTypes.func,
  location: PropTypes.object,
  setLocation: PropTypes.func,
  vlanName: PropTypes.object,
  setVlanName: PropTypes.func,
  ipScope: PropTypes.object,
  setIpScope: PropTypes.func,
  ipAddress: PropTypes.object,
  setIpAddress: PropTypes.func,
  netmask: PropTypes.object,
  setNetmask: PropTypes.func,
  gateway: PropTypes.object,
  setGateway: PropTypes.func,
  server: PropTypes.object,
  forUserID: PropTypes.string,
  gatewayOptions: PropTypes.array,
  setGatewayOptions: PropTypes.func,

  staticLocation: PropTypes.string,
  staticVlanName: PropTypes.string,
  staticVlanID: PropTypes.string,
  hideIpAddressSelect: PropTypes.bool,
};

export default PrivateNetworking;
