const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const CLEAR_USER = "CLEAR_USER";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER_DETAILS: {
      return { ...state, ...action.payload };
    }
    case CLEAR_USER: {
      return {};
    }
    default: {
      return state;
    }
  }
}

export const updateUserDetails = (data) => {
  return { type: UPDATE_USER_DETAILS, payload: data };
};

export const clearUser = () => {
  return { type: CLEAR_USER };
};
