export const UPDATE_TASKS = "UPDATE_TASKS";
export const CLEAR_TASKS = "CLEAR_TASKS";

export default function reducer(state = null, action) {
  switch (action.type) {
    case UPDATE_TASKS:
      return {
        data: action.payload.data,
        lastRequest: action.payload.lastRequest,
      };
    case CLEAR_TASKS: {
      return null;
    }
    default: {
      return state;
    }
  }
}

export const clearTasks = () => {
  return { type: CLEAR_TASKS };
};
