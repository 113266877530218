import React from "react";
import LayoutWithStripe from "../../../components/layout-with-stripe";
import BillingManageCreditCard from "./manage-credit-card";

export default function BillingManageCreditCardWrapper() {
  return (
    <LayoutWithStripe>
      <BillingManageCreditCard />
    </LayoutWithStripe>
  );
}
