import styles from "./server-hostname-label.module.scss";

import React, { useState, useEffect } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import isValidDomain from "is-valid-domain";
import generatePassword from "password-generator";
import { CustomText } from "@dreamvps/dream-ui/dist";
import { IconButton } from "@dreamvps/dream-ui/dist";
import GeneratePasswordModal from "../modals/generate-password";
import { usePrompt } from "@dreamvps/dream-utils/dist/hooks";

function ServerHostnameLabel({
  image,
  hostnames,
  setHostnames,
  password,
  setPassword,
  setIsNextButtonDisabled,
}) {
  const intl = useIntl();
  const prompt = usePrompt();

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] =
    useState(false);
  const [errorHostname, setErrorHostname] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  useEffect(() => {
    let errorHostname = false;
    let errorPassword = false;

    if (
      hostnames.filter(
        (hostname) => isValidDomain(hostname) && !hostname.includes("_")
      ).length < hostnames.length
    ) {
      errorHostname = intl.formatMessage({
        id: "server-hostname-label.invalid-hostname",
      });
    } else if (
      image.value?.toLowerCase().includes("cpanel") &&
      hostnames.filter((hostname) => hostname.split(".").length < 3).length
    ) {
      errorHostname = intl.formatMessage({
        id: "server-hostname-label.invalid-cpanel-hostname",
      });
    }

    if (passwordScore <= 1) {
      errorPassword = intl.formatMessage({
        id: "server-hostname-label.weak-password",
      });
    } else if (password !== passwordRepeat) {
      errorPassword = intl.formatMessage({
        id: "server-hostname-label.password-mismatch",
      });
    }

    setIsNextButtonDisabled(errorHostname || errorPassword ? true : false);

    setErrorHostname(errorHostname);
    setErrorPassword(errorPassword);
  }, [
    intl,
    setIsNextButtonDisabled,
    password,
    passwordRepeat,
    hostnames,
    passwordScore,
    image.value,
  ]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setHostnames(["dafner.test"]);

      let password;

      do {
        password = generatePassword(16, false, /([A-Za-z\d?-])/g);

        if (
          /\d/.test(password) &&
          /[a-z]/.test(password) &&
          /[A-Z]/.test(password)
        ) {
          break;
        }
      } while (true);

      setPassword(password);
      setPasswordRepeat(password);
    }
  }, [setHostnames, setPassword, setPasswordRepeat]);

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordRepeat(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  function handleHostnameChanged(e, idx) {
    hostnames[idx] = e.target.value;

    setHostnames([...hostnames]);
  }

  async function handleAutoRenameClicked() {
    const hostname = await prompt({
      title: intl.formatMessage({
        id: "server-hostname-label.choose-hostname.auto-rename",
      }),
      message: intl.formatMessage({
        id: "server-hostname-label.choose-hostname.auto-rename.content",
      }),
    });

    if (!hostname) {
      return;
    }

    for (let i = 0; i < hostnames.length; i++) {
      hostnames[i] = hostname.replace(/\$/g, i + 1);
    }

    setHostnames([...hostnames]);
  }

  return (
    <div className={styles.ServerHostnameLabelWrapper}>
      <div className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="server-hostname-label.choose-hostname" />
        </div>
        <div className={styles.container}>
          <div className={styles.text}>
            <FormattedMessage id="server-hostname-label.choose-hostname.text" />
          </div>
          {hostnames.map((hostname, key) => (
            <div key={key}>
              <CustomText
                autoFocus
                className={styles.input}
                value={hostname}
                onChange={(e) => handleHostnameChanged(e, key)}
                placeholder={intl.formatMessage(
                  {
                    id: "server-hostname-label.choose-hostname.server-hostname",
                  },
                  { number: key + 1 }
                )}
              />
            </div>
          ))}

          {hostnames.length > 1 && (
            <IconButton color="light-purple" onClick={handleAutoRenameClicked}>
              <FormattedMessage id="server-hostname-label.choose-hostname.auto-rename" />
            </IconButton>
          )}

          {errorHostname && <div className={styles.error}>{errorHostname}</div>}
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="server-hostname-label.password" />
        </div>
        <div className={styles.container}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "server-hostname-label.password.text",
              }),
            }}
          ></div>

          <div className="mb16">
            <CustomText
              type="password"
              placeholder={intl.formatMessage({
                id: "general.choose-password",
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb16">
            <CustomText
              type="password"
              placeholder={intl.formatMessage({
                id: "general.repeat-password",
              })}
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
          <div>
            <IconButton
              color="white"
              onClick={() => setIsGeneratePasswordModalOpen(true)}
            >
              <FormattedMessage id="general.generate-password" />
            </IconButton>
          </div>

          <div className={styles.passwordStrengthBarWrapper}>
            <PasswordStrengthBar
              password={password}
              onChangeScore={setPasswordScore}
            />
          </div>

          {errorPassword && <div className={styles.error}>{errorPassword}</div>}
        </div>
      </div>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </div>
  );
}

ServerHostnameLabel.propTypes = {
  image: PropTypes.object,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  hostnames: PropTypes.array,
  setHostnames: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
};

export default ServerHostnameLabel;
