import { SUPER_ADMIN, WHITELABEL } from "@dreamvps/dream-utils/dist/user";

export default function getSidebar(user, lang, intl) {
  if (user.role === SUPER_ADMIN) {
    return [
      {
        title: intl.formatMessage({ id: "sidebar.my-cloud" }),
        icon: "cloud",
        links: [
          {
            id: "servers",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.servers",
            }),
            href: "/[lang]/my-cloud/servers",
            as: `/${lang}/my-cloud/servers`,
          },
          {
            id: "templates",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.templates",
            }),
            href: "/[lang]/my-cloud/templates",
            as: `/${lang}/my-cloud/templates`,
          },
          {
            id: "groups",
            title: intl.formatMessage({ id: "sidebar.my-cloud.groups" }),
            href: "/[lang]/my-cloud/groups",
            as: `/${lang}/my-cloud/groups`,
          },
          {
            id: "networks",
            title: intl.formatMessage({ id: "sidebar.my-cloud.networks" }),
            href: "/[lang]/my-cloud/networks",
            as: `/${lang}/my-cloud/networks`,
          },
          {
            id: "wan",
            title: intl.formatMessage({ id: "sidebar.my-cloud.networks-wan" }),
            href: "/[lang]/my-cloud/networks/wan",
            as: `/${lang}/my-cloud/networks/wan`,
          },
          {
            id: "externals-ips",
            title: intl.formatMessage({ id: "sidebar.my-cloud.externals-ips" }),
            href: "/[lang]/my-cloud/networks/externals-ips",
            as: `/${lang}/my-cloud/networks/externals-ips`,
          },
          {
            id: "firewall",
            title: intl.formatMessage({ id: "sidebar.my-cloud.firewall" }),
            href: "/[lang]/my-cloud/firewall/security-groups",
            as: `/${lang}/my-cloud/firewall/security-groups`,
          },
          {
            id: "create-new-server",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.create-new-server",
            }),
            href: "/[lang]/my-cloud/create-new-server",
            as: `/${lang}/my-cloud/create-new-server`,
          },
        ],
      },
      {
        id: "services",
        title: intl.formatMessage({ id: "sidebar.services" }),
        icon: "email",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.services.dream-smtp" }),
            href: "/[lang]/services/smtp",
            as: `/${lang}/services/smtp`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.services.api" }),
            href: "/[lang]/services/api",
            as: `/${lang}/services/api`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.clients" }),
        icon: "account",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.clients.clients-list",
            }),
            href: "/[lang]/clients/clients-list",
            as: `/${lang}/clients/clients-list`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.online-users" }),
            href: "/[lang]/clients/online-users",
            as: `/${lang}/clients/online-users`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.bi-reports" }),
            href: "/[lang]/clients/bi-reports",
            as: `/${lang}/clients/bi-reports`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.untrusted-clients",
            }),
            href: "/[lang]/clients/untrusted-clients",
            as: `/${lang}/clients/untrusted-clients`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.support" }),
        icon: "support",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.support.my-tickets" }),
            href: "/[lang]/support/my-tickets",
            as: `/${lang}/support/my-tickets`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.billing" }),
        icon: "billing",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.billing.invoices" }),
            href: "/[lang]/billing/invoices",
            as: `/${lang}/billing/invoices`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.billing.create-new-invoice",
            }),
            href: "/[lang]/billing/create-new-invoice",
            as: `/${lang}/billing/create-new-invoice`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.leads-report" }),
            href: "/[lang]/billing/leads-report",
            as: `/${lang}/billing/leads-report`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.vat-report" }),
            href: "/[lang]/billing/vat-report",
            as: `/${lang}/billing/vat-report`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.coupons" }),
            href: "/[lang]/billing/coupons",
            as: `/${lang}/billing/coupons`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.about-to-expire-cc" }),
            href: "/[lang]/billing/about-to-expire-cc",
            as: `/${lang}/billing/about-to-expire-cc`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.traffic-form" }),
            href: "/[lang]/billing/bandwidth-form",
            as: `/${lang}/billing/bandwidth-form`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.monthly-cron" }),
            href: "/[lang]/billing/monthly-cron",
            as: `/${lang}/billing/monthly-cron`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.hourly-cron" }),
            href: "/[lang]/billing/hourly-cron",
            as: `/${lang}/billing/hourly-cron`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.change-payment-form" }),
            href: "/[lang]/billing/change-payment-form",
            as: `/${lang}/billing/change-payment-form`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.unpaid-customers" }),
            href: "/[lang]/billing/unpaid-customers",
            as: `/${lang}/billing/unpaid-customers`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.domains" }),
            href: "/[lang]/billing/domains",
            as: `/${lang}/billing/domains`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.calculator" }),
            href: "/[lang]/billing/calculator",
            as: `/${lang}/billing/calculator`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.account" }),
        icon: "account",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.account.account-details",
            }),
            href: "/[lang]/account/account-details",
            as: `/${lang}/account/account-details`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.permissions" }),
            href: "/[lang]/account/permissions",
            as: `/${lang}/account/permissions`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.security" }),
            href: "/[lang]/account/security",
            as: `/${lang}/account/security`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.notifications-management",
            }),
            href: "/[lang]/account/notifications-management",
            as: `/${lang}/account/notifications-management`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.general-settings",
            }),
            href: "/[lang]/account/general-settings",
            as: `/${lang}/account/general-settings`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.system-management" }),
        icon: "management",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.management.products",
            }),
            href: "/[lang]/management/products",
            as: `/${lang}/management/products`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.servers-and-prices",
            }),
            href: "/[lang]/management/servers-and-prices",
            as: `/${lang}/management/servers-and-prices`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.iso",
            }),
            href: "/[lang]/management/iso",
            as: `/${lang}/management/iso`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.jobs-manager",
            }),
            href: "/[lang]/management/jobs-manager",
            as: `/${lang}/management/jobs-manager`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.proxmox-settings",
            }),
            href: "/[lang]/management/proxmox-settings",
            as: `/${lang}/management/proxmox-settings`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.nodes-status",
            }),
            href: "/[lang]/management/nodes-status",
            as: `/${lang}/management/nodes-status`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.management.blacklist" }),
            href: "/[lang]/management/blacklist",
            as: `/${lang}/management/blacklist`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.management.whitelabel" }),
            href: "/[lang]/management/whitelabel",
            as: `/${lang}/management/whitelabel`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.management.emails" }),
            href: "/[lang]/management/emails",
            as: `/${lang}/management/emails`,
          },
        ],
      },
    ];
  } else if (user.role === WHITELABEL) {
    return [
      {
        title: intl.formatMessage({ id: "sidebar.my-cloud" }),
        icon: "cloud",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.my-cloud.servers",
            }),
            href: "/[lang]/my-cloud/servers",
            as: `/${lang}/my-cloud/servers`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.my-cloud.groups" }),
            href: "/[lang]/my-cloud/groups",
            as: `/${lang}/my-cloud/groups`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.my-cloud.networks" }),
            href: "/[lang]/my-cloud/networks",
            as: `/${lang}/my-cloud/networks`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.my-cloud.firewall" }),
            href: "/[lang]/my-cloud/firewall/security-groups",
            as: `/${lang}/my-cloud/firewall/security-groups`,
          },
          {
            id: "create-new-server",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.create-new-server",
            }),
            href: "/[lang]/my-cloud/create-new-server",
            as: `/${lang}/my-cloud/create-new-server`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.clients" }),
        icon: "account",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.clients.clients-list",
            }),
            href: "/[lang]/clients/clients-list",
            as: `/${lang}/clients/clients-list`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.online-users" }),
            href: "/[lang]/clients/online-users",
            as: `/${lang}/clients/online-users`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.support" }),
        icon: "support",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.support.my-tickets" }),
            href: "/[lang]/support/my-tickets",
            as: `/${lang}/support/my-tickets`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.support.open-new-ticket",
            }),
            href: "/[lang]/support/open-new-ticket",
            as: `/${lang}/support/open-new-ticket`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.support.departments",
            }),
            href: "/[lang]/support/departments",
            as: `/${lang}/support/departments`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.billing" }),
        icon: "billing",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.billing.invoices" }),
            href: "/[lang]/billing/invoices",
            as: `/${lang}/billing/invoices`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.billing.create-new-invoice",
            }),
            href: "/[lang]/billing/create-new-invoice",
            as: `/${lang}/billing/create-new-invoice`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.traffic-form" }),
            href: "/[lang]/billing/bandwidth-form",
            as: `/${lang}/billing/bandwidth-form`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.unpaid-customers" }),
            href: "/[lang]/billing/unpaid-customers",
            as: `/${lang}/billing/unpaid-customers`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.account" }),
        icon: "account",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.account.account-details",
            }),
            href: "/[lang]/account/account-details",
            as: `/${lang}/account/account-details`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.permissions" }),
            href: "/[lang]/account/permissions",
            as: `/${lang}/account/permissions`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.security" }),
            href: "/[lang]/account/security",
            as: `/${lang}/account/security`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.general-settings",
            }),
            href: "/[lang]/account/general-settings",
            as: `/${lang}/account/general-settings`,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: "sidebar.system-management" }),
        icon: "ram",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.management.products",
            }),
            href: "/[lang]/management/products",
            as: `/${lang}/management/products`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.management.servers-and-prices",
            }),
            href: "/[lang]/management/servers-and-prices",
            as: `/${lang}/management/servers-and-prices`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.management.emails" }),
            href: "/[lang]/management/emails",
            as: `/${lang}/management/emails`,
          },
        ],
      },
    ];
  } else {
    return [
      {
        id: "my-cloud",
        title: intl.formatMessage({ id: "sidebar.my-cloud" }),
        icon: "cloud",
        links: [
          {
            id: "servers",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.servers",
            }),
            href: "/[lang]/my-cloud/servers",
            as: `/${lang}/my-cloud/servers`,
          },
          {
            id: "templates",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.templates",
            }),
            href: "/[lang]/my-cloud/templates",
            as: `/${lang}/my-cloud/templates`,
          },
          {
            id: "products",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.products",
            }),
            href: "/[lang]/my-cloud/products",
            as: `/${lang}/my-cloud/products`,
          },
          {
            id: "groups",
            title: intl.formatMessage({ id: "sidebar.my-cloud.groups" }),
            href: "/[lang]/my-cloud/groups",
            as: `/${lang}/my-cloud/groups`,
          },
          {
            id: "networks",
            title: intl.formatMessage({ id: "sidebar.my-cloud.networks" }),
            href: "/[lang]/my-cloud/networks",
            as: `/${lang}/my-cloud/networks`,
          },
          {
            id: "firewall",
            title: intl.formatMessage({ id: "sidebar.my-cloud.firewall" }),
            href: "/[lang]/my-cloud/firewall/security-groups",
            as: `/${lang}/my-cloud/firewall/security-groups`,
          },
          {
            id: "create-new-server",
            title: intl.formatMessage({
              id: "sidebar.my-cloud.create-new-server",
            }),
            href: "/[lang]/my-cloud/create-new-server",
            as: `/${lang}/my-cloud/create-new-server`,
          },
        ],
      },
      {
        id: "services",
        title: intl.formatMessage({ id: "sidebar.services" }),
        icon: "email",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.services.dream-smtp" }),
            href: "/[lang]/services/smtp",
            as: `/${lang}/services/smtp`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.services.api" }),
            href: "/[lang]/services/api",
            as: `/${lang}/services/api`,
          },
        ],
      },
      {
        id: "support",
        title: intl.formatMessage({ id: "sidebar.support" }),
        icon: "support",
        links: [
          {
            id: "my-tickets",
            title: intl.formatMessage({ id: "sidebar.support.my-tickets" }),
            href: "/[lang]/support/my-tickets",
            as: `/${lang}/support/my-tickets`,
          },
          {
            id: "open-new-ticket",
            title: intl.formatMessage({
              id: "sidebar.support.open-new-ticket",
            }),
            href: "/[lang]/support/open-new-ticket",
            as: `/${lang}/support/open-new-ticket`,
          },
        ],
      },
      {
        id: "billing",
        title: intl.formatMessage({ id: "sidebar.billing" }),
        icon: "billing",
        links: [
          {
            title: intl.formatMessage({ id: "sidebar.billing.invoices" }),
            href: "/[lang]/billing/invoices",
            as: `/${lang}/billing/invoices`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.manage-credit-card" }),
            href: "/[lang]/billing/manage-credit-card",
            as: `/${lang}/billing/manage-credit-card`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.traffic-form" }),
            href: "/[lang]/billing/bandwidth-form",
            as: `/${lang}/billing/bandwidth-form`,
          },
        ],
      },
      {
        id: "account",
        title: intl.formatMessage({ id: "sidebar.account" }),
        icon: "account",
        links: [
          {
            title: intl.formatMessage({
              id: "sidebar.account.account-details",
            }),
            href: "/[lang]/account/account-details",
            as: `/${lang}/account/account-details`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.permissions" }),
            href: "/[lang]/account/permissions",
            as: `/${lang}/account/permissions`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.security" }),
            href: "/[lang]/account/security",
            as: `/${lang}/account/security`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.notifications-management",
            }),
            href: "/[lang]/account/notifications-management",
            as: `/${lang}/account/notifications-management`,
          },
          {
            title: intl.formatMessage({ id: "sidebar.account.emails-histroy" }),
            href: "/[lang]/account/emails-histroy",
            as: `/${lang}/account/emails-histroy`,
          },
          {
            title: intl.formatMessage({
              id: "sidebar.account.general-settings",
            }),
            href: "/[lang]/account/general-settings",
            as: `/${lang}/account/general-settings`,
          },
          // {
          //   title: intl.formatMessage({ id: "sidebar.account.online-users" }),
          //   href: "/[lang]/account/online-users",
          //   as: `/${lang}/account/online-users`,
          // },
          {
            title: intl.formatMessage({ id: "sidebar.account.logs" }),
            href: "/[lang]/account/logs",
            as: `/${lang}/account/logs`,
          },
        ],
      },
    ];
  }
}
