import styles from "./sidebar-mobile.module.scss";

import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useLang, useRoles, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { ReactComponent as CancelSvg } from "../svgs/cancel.svg";
import getSidebar from "./sidebar-json";

function SidebarMobile({ isOpen, onClose }) {
  const intl = useIntl();
  const lang = useLang("en");
  const user = useUser();
  const { isAllowed } = useRoles();

  const [openItem, setOpenItem] = useState(null);

  const sidebar = useMemo(() => {
    const sidebar = getSidebar(user, lang, intl);

    return sidebar.filter(
      (item) => item.id !== "create-new-server" || isAllowed("servers.create")
    );
  }, [intl, lang, user, isAllowed]);

  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
      <CancelSvg onClick={() => onClose(true)} className={styles.close} />

      {sidebar.map((item, key) => (
        <Fragment key={key}>
          <div
            className={`${styles.mainItem} ${
              openItem === key ? styles.selected : ""
            }`}
            onClick={() => setOpenItem(key)}
          >
            {item.title}
          </div>
          {item.links.map((link, key2) => (
            <div
              key={key2}
              className={`${styles.item} ${
                item.links.length - 1 === key2 ? styles.last : ""
              } ${openItem === key ? "" : styles.hidden}`}
            >
              <Link href={link.href} to={link.as} onClick={() => onClose()}>
                {link.title}
              </Link>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
}

SidebarMobile.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SidebarMobile;
