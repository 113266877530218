import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton } from "@dreamvps/dream-ui/dist";
import AutoScale from "../../auto-scale";
import { useAlert } from "@dreamvps/dream-utils/dist/hooks";

function AutoScaleConfigModal({
  autoScale,
  setAutoScale,
  image,
  isOpen,
  onClose,
}) {
  const alert = useAlert();
  const intl = useIntl();

  const [error, setError] = useState(null);

  async function handleCloseClicked() {
    if (error) {
      await alert({
        title: intl.formatMessage({ id: "general.error" }),
        message: error,
      });

      return;
    }

    autoScale.conditions = autoScale.conditions.filter(
      (item) => item.ifOption && item.sideOption
    );
    setAutoScale({ ...autoScale });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={handleCloseClicked}
      size="xl"
    >
      <ModalHeader toggle={handleCloseClicked}>
        <FormattedMessage id="auto-scale-config-modal.title" />
      </ModalHeader>
      <ModalBody>
        <AutoScale
          autoScale={autoScale}
          setAutoScale={setAutoScale}
          image={image.value}
          error={error}
          setError={setError}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={handleCloseClicked}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AutoScaleConfigModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  autoScale: PropTypes.object,
  setAutoScale: PropTypes.func,
  image: PropTypes.object,
};

export default AutoScaleConfigModal;
