import produce from "immer";

const UPDATE_SERVERS = "UPDATE_SERVERS";
const CLEAR_SERVERS = "CLEAR_SERVERS";
const UPDATE_IS_WORKING = "UPDATE_IS_WORKING";

export default function reducer(state = null, action) {
  switch (action.type) {
    case UPDATE_SERVERS: {
      return action.payload.map((server) => {
        if (!state) {
          return server;
        }

        if (!server.serverIsLive) {
          server.qemuStatus = { status: "Terminated" };
        }

        return server;
      });
    }
    case UPDATE_IS_WORKING: {
      return produce(state, (servers) => {
        servers.forEach((server) => {
          if (action.serversIds.includes(server._id)) {
            server.isWorking = action.state;
          }
        });
      });
    }
    case CLEAR_SERVERS: {
      return null;
    }
    default: {
      return state;
    }
  }
}

export const updateServers = (servers) => {
  return { type: UPDATE_SERVERS, payload: servers };
};

export const clearServers = () => {
  return { type: CLEAR_SERVERS };
};

export const updateIsWorking = (serversIds, state) => {
  return { type: UPDATE_IS_WORKING, serversIds, state };
};
