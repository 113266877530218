import styles from "./sidebar.module.scss";

import React, { useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SvgSelector } from "@dreamvps/dream-ui/dist";
import { ReactComponent as LogoMini } from "../svgs/logo-mini.svg";
import Widgets from "./widgets";
import { useLang, useRoles, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { useDesignTemplate, useWLDVPS } from "../../utils/hooks";
import getSidebar from "./sidebar-json";
import { setSidebarSelection } from "../../store/settings";

function Sidebar() {
  const intl = useIntl();
  const lang = useLang("en");
  const user = useUser();
  const wldvps = useWLDVPS();
  const designTemplate = useDesignTemplate();
  const router = useHistory();
  const { isAllowed } = useRoles();
  const dispatch = useDispatch();
  const { sidebarSelection } = useSelector((state) => ({
    sidebarSelection: state.settings.sidebarSelection,
  }));

  const blockRef = useRef(false);

  const sidebar = useMemo(
    () => getSidebar(user, lang, intl),
    [intl, lang, user]
  );

  useEffect(() => {
    if (blockRef.current) {
      return;
    }

    sidebar.forEach((item) => {
      item.links.forEach((link) => {
        if (router.location.pathname.includes(link.as)) {
          blockRef.current = true;

          dispatch(
            setSidebarSelection({
              selectedSidebarItem: item,
              selectedSidebarSubItem: link,
            })
          );
        }
      });
    });

    if (!blockRef.current) {
      dispatch(
        setSidebarSelection({
          selectedSidebarItem: sidebar[0],
          selectedSidebarSubItem: sidebar[0].links[0],
        })
      );
    }
  }, [router, sidebar, dispatch]);

  function handleSidebarFilter(item) {
    if (item.id === "create-new-server" && !isAllowed("servers.create")) {
      return false;
    }

    return true;
  }

  function handleSidebarItemClicked(item) {
    dispatch(
      setSidebarSelection({
        selectedSidebarItem: item,
        selectedSidebarSubItem: item.links[0],
      })
    );
  }

  function handleSidebarSubItemClicked(subItem) {
    dispatch(
      setSidebarSelection({
        selectedSidebarItem: sidebarSelection.selectedSidebarItem,
        selectedSidebarSubItem: subItem,
      })
    );
  }

  function isItemDisabled(item) {
    if (
      user.current_parent ||
      user.registerStep === -1 ||
      [
        "servers",
        "my-tickets",
        "open-new-ticket",
        "my-cloud",
        "support",
      ].includes(item.id)
    ) {
      return "";
    }

    return styles.disabled;
  }

  function renderSubItem() {
    if (!sidebarSelection.selectedSidebarItem) {
      return null;
    }

    return (
      <div className={styles.items}>
        <div className={styles.currentPage}>
          <SvgSelector icon={sidebarSelection.selectedSidebarItem.icon} />
          <span>{sidebarSelection.selectedSidebarItem.title}</span>
        </div>
        <div className={styles.line}>
          <hr />
        </div>
        <div className={`mt24 ${styles.subItems}`}>
          {sidebarSelection.selectedSidebarItem.links
            .filter(handleSidebarFilter)
            .map((link, idx) => (
              <Link
                href={link.href}
                to={link.as}
                key={idx}
                className={`mb16 ${styles.subItem} ${
                  sidebarSelection.selectedSidebarSubItem === link
                    ? styles.selected
                    : ""
                } ${isItemDisabled(link)}`}
                onClick={() => handleSidebarSubItemClicked(link)}
              >
                {link.title}
              </Link>
            ))}
        </div>
      </div>
    );
  }

  function renderMiniLogo() {
    if (designTemplate) {
      return (
        <div className={styles.designTemplateLogoWrapper}>
          <img
            className={`${styles.miniLogo} ${styles.designTemplateLogo} mt28 mb28`}
            src={`/img/wldvps/${designTemplate}/favicon.ico`}
          />
        </div>
      );
    }
    if (wldvps) {
      <div className={`${styles.miniLogo} mt28 mb28`} />;
    }

    return <LogoMini className={`${styles.miniLogo} mt28 mb28`} />;
  }

  return (
    <div className={`${styles.sidebarWrapper}`}>
      <div className={styles.sidebarFloat}>
        <div className={styles.mainNav}>
          {renderMiniLogo()}

          {sidebar.filter(handleSidebarFilter).map((item, idx) => (
            <Link
              key={idx}
              href={item.links[0].href}
              to={item.links[0].as}
              className={`${styles.box} ${
                sidebarSelection.selectedSidebarItem?.title === item.title
                  ? styles.selected
                  : ""
              } ${isItemDisabled(item)}`}
              onClick={() => handleSidebarItemClicked(item)}
            >
              <SvgSelector icon={item.icon} />
              {item.title}
            </Link>
          ))}
        </div>
        <div className={styles.subNav}>
          {renderSubItem()}

          {!wldvps && user.general_settings?.widget === "show" && (
            <div className={styles.widgetsWrapper}>
              <Widgets />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Sidebar);
