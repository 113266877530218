import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CompleteRegistrationModalStep1 from "./step1";
import CompleteRegistrationModalStep2 from "./step2";
import CompleteRegistrationModalStep3 from "./step3";
import { useUser } from "@dreamvps/dream-utils/dist/hooks";
import LayoutWithStripe from "../../layout-with-stripe";

function CompleteRegistrationModal({
  location,
  image,
  cpu,
  ram_mb,
  ssd_gb,
  additional_ssd_gb,
  backup,
  privateNetworking,
  paymentType,
  hostnames,
  password,
  tag,
  group,
  ipType,
  bypassPayment,
  attachServerToClient,
  selectedFreeDays,
  publicNetworking,
  promotionCode,
  securityGroup,
  isOpen,
  onClose,
}) {
  const user = useUser();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (user) {
      setStep(user.registerStep);
    }
  }, [user]);

  function handleNextClicked() {
    const newStep = step + 1;

    if (newStep > 3) {
      onClose(true);
    }

    setStep(newStep);
  }

  function handleBackClicked() {
    setStep(step - 1);
  }

  return (
    <Modal
      className={styles.completeRegistrationModalWrapper}
      style={{ maxWidth: "816px", width: "80%" }}
      isOpen={isOpen}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="complete-registration-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.steps}>
          <div className={`${styles.step} ${step === 1 && styles.selected}`}>
            <div className={styles.number}>1</div>
            <div className={styles.text}>
              <FormattedMessage id="complete-registration-modal.personal-information" />
            </div>
          </div>
          <div className={`${styles.step} ${step === 2 && styles.selected}`}>
            <div className={styles.number}>2</div>
            <div className={styles.text}>
              <FormattedMessage id="complete-registration-modal.phone-verification" />
            </div>
          </div>
          <div className={`${styles.step} ${step === 3 && styles.selected}`}>
            <div className={styles.number}>3</div>
            <div className={styles.text}>
              <FormattedMessage id="complete-registration-modal.payment-details" />
            </div>
          </div>
        </div>

        {step === 1 && (
          <CompleteRegistrationModalStep1
            onNext={handleNextClicked}
            onClose={() => onClose(false)}
          />
        )}

        {step === 2 && (
          <CompleteRegistrationModalStep2
            onNext={handleNextClicked}
            onBack={handleBackClicked}
          />
        )}

        {step === 3 && (
          <LayoutWithStripe>
            <CompleteRegistrationModalStep3
              onNext={handleNextClicked}
              location={location}
              image={image}
              cpu={cpu}
              ram_mb={ram_mb}
              ssd_gb={ssd_gb}
              additional_ssd_gb={additional_ssd_gb}
              backup={backup}
              privateNetworking={privateNetworking}
              paymentType={paymentType}
              hostnames={hostnames}
              password={password}
              tag={tag}
              group={group}
              ipType={ipType}
              bypassPayment={bypassPayment}
              attachServerToClient={attachServerToClient}
              selectedFreeDays={selectedFreeDays}
              publicNetworking={publicNetworking}
              promotionCode={promotionCode}
              securityGroup={securityGroup}
            />
          </LayoutWithStripe>
        )}
      </ModalBody>
    </Modal>
  );
}

CompleteRegistrationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  cpu: PropTypes.number,
  ram_mb: PropTypes.number,
  ssd_gb: PropTypes.number,
  additional_ssd_gb: PropTypes.number,
  backup: PropTypes.bool,
  privateNetworking: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  paymentType: PropTypes.string,
  hostnames: PropTypes.array,
  password: PropTypes.string,
  tag: PropTypes.string,
  group: PropTypes.string,
  ipType: PropTypes.number,
  bypassPayment: PropTypes.bool,
  attachServerToClient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedFreeDays: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  publicNetworking: PropTypes.bool,
  promotionCode: PropTypes.string,
  securityGroup: PropTypes.bool,
};

export default CompleteRegistrationModal;
