import styles from "./index.module.scss";

import React from "react";
import { useIntl } from "react-intl";
import { useUser } from "@dreamvps/dream-utils/dist/hooks";
import { getSuspendedReason } from "@dreamvps/dream-utils/dist/user";
import { Box } from "@dreamvps/dream-ui/dist";
import BillingManageCreditCardWrapper from "../../pages/[lang]/billing/manage-credit-card-wrapper";

export default function LockedUser() {
  const intl = useIntl();
  const user = useUser();

  if (user.status === "USER_STATUS_SUSPENDED_PAYMENT_ISSUE") {
    return <BillingManageCreditCardWrapper />;
  }

  return (
    <div className={styles.wrapper}>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "locked-user.description" },
              {
                reason: intl.formatMessage({
                  id: `locked-user.issue-${getSuspendedReason(user.status)}`,
                }),
                phoneNumber: user.companyPhone,
                b: (arr) => `<b>${arr[0]}</b>`,
              }
            ),
          }}
        ></div>
      </Box>
    </div>
  );
}
