import styles from "./plan.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  Checkbox,
  IconButton,
  Counter,
  SwitchSelector,
} from "@dreamvps/dream-ui/dist";
import { ReactComponent as CpuSvg } from "../svgs/cpu.svg";
import { ReactComponent as RamSvg } from "../svgs/ram.svg";
import { ReactComponent as SSDSvg } from "../svgs/ssd.svg";
import { ReactComponent as MinusRedSvg } from "../svgs/minus-red.svg";
import { useUser } from "@dreamvps/dream-utils/dist/hooks";
import { getClosestPrice } from "@dreamvps/dream-utils/dist/billing";
import AutoScaleConfigModal from "../modals/auto-scale-config";
import { SUPER_ADMIN } from "@dreamvps/dream-utils/dist/user";

function Plan({
  withTitle = true,
  os,
  image = {},
  cpu,
  setCpu,
  ram_mb,
  setRamMB,
  ssd_gb,
  setSsdGB,
  additional_ssd_gb,
  setAdditionalSsdGb,
  paymentType,
  setPaymentType,
  hidePaymentType = false,
  setIsNextButtonDisabled,
  ssd_gbMin = false,
  additional_ssd_gbMin = false,
  clone = false,
  autoScale,
  setAutoScale,
  location,
}) {
  const intl = useIntl();
  const user = useUser();
  const { serverToClone } = useSelector((state) => ({
    serverToClone: state.settings.serverToClone,
  }));

  const [cloneSetResources, setCloneSetResources] = useState(false);

  const [isAutoScaleConfigModalOpen, setIsAutoScaleConfigModalOpen] =
    useState(false);

  useEffect(() => {
    setIsNextButtonDisabled(
      os?.includes("Windows") && (ram_mb < 2048 || ssd_gb < 30)
    );
  }, [setIsNextButtonDisabled, os, ram_mb, ssd_gb]);

  useEffect(() => {
    if (ssd_gbMin && ssd_gb < ssd_gbMin) {
      setSsdGB(ssd_gbMin);
    }
  }, [setSsdGB, ssd_gb, ssd_gbMin]);

  // useEffect(() => {
  //   if (autoScale?.activated && ram_mb < 2048) {
  //     setRamMB(2048);
  //   }
  // }, [autoScale, ram_mb, setRamMB]);

  useEffect(() => {
    if (
      additional_ssd_gbMin &&
      additional_ssd_gb > 0 &&
      additional_ssd_gb < additional_ssd_gbMin
    ) {
      setAdditionalSsdGb(additional_ssd_gbMin);
    }
  }, [additional_ssd_gb, additional_ssd_gbMin, setAdditionalSsdGb]);

  useEffect(() => {
    if (os?.includes("Windows")) {
      if (ram_mb < 2048) {
        setRamMB(2048);
      }

      if (ssd_gb < 30) {
        setSsdGB(30);
      }
    }
  }, [os, setRamMB, setSsdGB, ram_mb, ssd_gb]);

  useEffect(() => {
    if (clone && !cloneSetResources) {
      setCloneSetResources(true);
      setCpu(serverToClone.cpu);
      setRamMB(serverToClone.ram_mb);
      setSsdGB(serverToClone.ssd_gb);
      setAdditionalSsdGb(serverToClone.additional_ssd_gb);
    }
  }, [
    clone,
    serverToClone,
    setAdditionalSsdGb,
    setCpu,
    setRamMB,
    setSsdGB,
    cloneSetResources,
  ]);

  useEffect(() => {
    if (autoScale?.activated && autoScale.conditions.length === 0) {
      setPaymentType("hourly");
      setIsAutoScaleConfigModalOpen(true);
    }
  }, [autoScale, setPaymentType]);

  function handleCounterClicked(type, oldValue, setValue, newValue) {
    const price = getClosestPrice(
      newValue,
      user.productPrices[type],
      newValue > oldValue ? "up" : "down"
    );

    setValue(price.value);
  }

  function handleAutoScaleActivatedChanged(e) {
    autoScale.activated = e.target.checked;
    setAutoScale({ ...autoScale });
  }

  function handleAutoScaleConfigModalOpen() {
    setIsAutoScaleConfigModalOpen(true);
  }

  function handleAutoScaleConfigModalClosed() {
    setIsAutoScaleConfigModalOpen(false);
  }

  const switchSelectorItems = useMemo(() => {
    return [
      {
        title: intl.formatMessage({ id: "general.hourly" }),
        selected: paymentType === "hourly",
        value: "hourly",
      },
      {
        title: intl.formatMessage({ id: "general.monthly" }),
        selected: paymentType === "monthly",
        value: "monthly",
        disabled: autoScale?.activated,
      },
    ];
  }, [intl, paymentType, autoScale]);

  return (
    <div className={`${styles.planWrapper} ${withTitle && styles.padding}`}>
      {withTitle && (
        <div className={styles.title}>
          <FormattedMessage id="plan.title" />
        </div>
      )}

      {user.role === SUPER_ADMIN &&
        image.support_autoscale &&
        location !== "US" && (
          <div className={styles.autoscaleWrapper}>
            <Checkbox
              label="plan.autoscale-info"
              checked={autoScale.activated}
              onChange={handleAutoScaleActivatedChanged}
            />
            {autoScale.activated && (
              <>
                <IconButton
                  color="light-purple"
                  onClick={handleAutoScaleConfigModalOpen}
                >
                  <FormattedMessage id="plan.autoscale-config" />
                </IconButton>
                {autoScale.conditions.length > 0 && (
                  <div>
                    <FormattedMessage
                      tagName="b"
                      id="plan.autoscale-rules"
                      values={{ total: autoScale.conditions.length }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}

      <div className={styles.packages}>
        <div className={styles.hardwareBox}>
          <div className={styles.title}>
            <FormattedMessage
              id={
                autoScale?.activated
                  ? "plan.hardware-box.autoscale.title"
                  : "plan.hardware-box.title"
              }
            />
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <CpuSvg />
              <span className={styles.hardwareTitle}>CPU</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                count={cpu}
                setCount={(value) =>
                  handleCounterClicked("cpu", cpu, setCpu, value)
                }
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <RamSvg />
              <span className={styles.hardwareTitle}>RAM</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                render={ram_mb / 1024}
                count={ram_mb}
                setCount={(value) =>
                  handleCounterClicked("ram_mb", ram_mb, setRamMB, value)
                }
                delta={1024}
                extraText="GB"
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <SSDSvg />
              <span className={styles.hardwareTitle}>SSD NVMe #1</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                count={ssd_gb}
                setCount={(value) =>
                  handleCounterClicked("ssd_gb", ssd_gb, setSsdGB, value)
                }
                extraText="GB"
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          {additional_ssd_gb > 0 && (
            <div className={styles.hardware}>
              <div className={styles.hardwareTitleWrapper}>
                <SSDSvg />
                <span className={styles.hardwareTitle}>SSD NVMe #2</span>
              </div>
              <div className={styles.counterSection}>
                <Counter
                  count={additional_ssd_gb}
                  setCount={(value) =>
                    handleCounterClicked(
                      "additional_ssd_gb",
                      additional_ssd_gb,
                      setAdditionalSsdGb,
                      value
                    )
                  }
                  extraText="GB"
                />
                <MinusRedSvg onClick={() => setAdditionalSsdGb(0)} />
              </div>
            </div>
          )}
          {additional_ssd_gb === 0 && (
            <div className={styles.addButton}>
              <IconButton
                color="white"
                icon="plus"
                onClick={() => setAdditionalSsdGb(10)}
              >
                <FormattedMessage id="plan.hardware-box.add-additional-disk" />
              </IconButton>
            </div>
          )}

          {!hidePaymentType && (
            <>
              <div className={styles.hr}>
                <hr />
              </div>
              {autoScale.activated && (
                <div className={styles.onlyHourly}>
                  <FormattedMessage id="plan.auto-scale.only-hourly" />
                </div>
              )}
              <div className={styles.paymentType}>
                <div className={styles.playmentTypeText}>
                  <FormattedMessage id="general.payment-type" />
                </div>
                <div>
                  <SwitchSelector
                    items={switchSelectorItems}
                    onChange={(value) => setPaymentType(value)}
                  />
                </div>
              </div>

              {paymentType === "monthly" && (
                <div
                  className={styles.explanations}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "plan.hardware-box.payment-type-explanations-monthly",
                    }),
                  }}
                ></div>
              )}
              {paymentType === "hourly" && (
                <div
                  className={styles.explanations}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "plan.hardware-box.payment-type-explanations-hourly",
                    }),
                  }}
                ></div>
              )}
            </>
          )}
        </div>
      </div>

      {typeof image === "object" && (
        <AutoScaleConfigModal
          isOpen={isAutoScaleConfigModalOpen}
          onClose={handleAutoScaleConfigModalClosed}
          autoScale={autoScale}
          setAutoScale={setAutoScale}
          image={image}
        />
      )}
    </div>
  );
}

Plan.propTypes = {
  withTitle: PropTypes.bool,
  os: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cpu: PropTypes.number,
  setCpu: PropTypes.func,
  ram_mb: PropTypes.number,
  setRamMB: PropTypes.func,
  ssd_gb: PropTypes.number,
  setSsdGB: PropTypes.func,
  additional_ssd_gb: PropTypes.number,
  setAdditionalSsdGb: PropTypes.func,
  paymentType: PropTypes.string,
  setPaymentType: PropTypes.func,
  hidePaymentType: PropTypes.bool,
  setIsNextButtonDisabled: PropTypes.func,
  ssd_gbMin: PropTypes.number,
  additional_ssd_gbMin: PropTypes.number,
  autoScale: PropTypes.object,
  setAutoScale: PropTypes.func,
  clone: PropTypes.bool,
  location: PropTypes.string,
};

export default Plan;
