import styles from "./index.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IconButton,
  CustomReactSelect,
  Checkbox,
} from "@dreamvps/dream-ui/dist";
import { useUser } from "@dreamvps/dream-utils/dist/hooks";
import { readableBytes } from "@dreamvps/dream-utils/dist/servers";
import { trustPointLimits } from "@dreamvps/dream-utils/dist/user";
import { ReactComponent as InfoSvg } from "../svgs/info.svg";
import { Tooltip } from "reactstrap";

const ifOptions = [
  { label: "CPU usage", value: "cpu-usage" },
  { label: "RAM usage", value: "ram-usage" },
];

const sideOptions = [
  { label: "Above", value: "above" },
  { label: "Below", value: "below" },
];

const intervalUpscaleOptions = [
  { label: "10 seconds", value: 10 },
  { label: "20 seconds", value: 20 },
  { label: "30 seconds", value: 30 },
  { label: "40 seconds", value: 40 },
  { label: "50 seconds", value: 50 },
  { label: "1 minute", value: 60 },
  { label: "5 minutes", value: 300 },
  { label: "10 minutes", value: 600 },
  { label: "20 minutes", value: 1200 },
  { label: "30 minutes", value: 1800 },
  { label: "40 minutes", value: 2400 },
  { label: "50 minutes", value: 3000 },
  { label: "1 hour", value: 3600 },
];

const intervalDownscaleOptions = [
  { label: "1 minute", value: 60 },
  { label: "5 minutes", value: 300 },
  { label: "10 minutes", value: 600 },
  { label: "20 minutes", value: 1200 },
  { label: "30 minutes", value: 1800 },
  { label: "40 minutes", value: 2400 },
  { label: "50 minutes", value: 3000 },
  { label: "1 hour", value: 3600 },
];

const upscaleLevelOptions = [
  { label: "x1 resources", value: 1 },
  { label: "x2 resources", value: 2 },
  { label: "x4 resources", value: 4 },
  { label: "Maximum resources", value: 0 },
];

function AutoScale({ autoScale, setAutoScale, image, error, setError }) {
  const intl = useIntl();
  const intlRef = useRef(intl);
  const user = useUser();

  const autoScaleRef = useRef(autoScale);
  autoScaleRef.current = autoScale;

  const [isTooltipOpen, setIsTooltipOpen] = useState({});

  const handlePredefinedProgramClicked = useCallback(
    (type) => {
      let precs, upscaleLevel;

      switch (type) {
        case "low-traffic": {
          precs = [70, 50];
          upscaleLevel = upscaleLevelOptions[0];
          break;
        }
        case "medium-traffic": {
          precs = [60, 40];
          upscaleLevel = upscaleLevelOptions[1];

          break;
        }
        case "high-traffic": {
          precs = [40, 20];
          upscaleLevel = upscaleLevelOptions[2];

          break;
        }
      }

      if (!precs) {
        return;
      }

      autoScaleRef.current.conditions = [
        {
          ifOption: "cpu-usage",
          sideOption: "above",
          prec: precs[0],
        },
        {
          ifOption: "cpu-usage",
          sideOption: "below",
          prec: precs[1],
        },
        {
          ifOption: "ram-usage",
          sideOption: "above",
          prec: precs[0],
        },
        {
          ifOption: "ram-usage",
          sideOption: "below",
          prec: precs[1],
        },
      ];

      if (image.includes("Windows")) {
        autoScaleRef.current.conditions =
          autoScaleRef.current.conditions.filter(
            (item) => item.sideOption === "above"
          );
      }

      autoScaleRef.current.upscale_level = upscaleLevel.value;

      setAutoScale({ ...autoScaleRef.current });
    },
    [setAutoScale, image]
  );

  useEffect(() => {
    if (
      autoScale.conditions.length === 0 ||
      (image.includes("Windows") && autoScale.conditions.length !== 2) ||
      (!image.includes("Windows") && autoScale.conditions.length !== 4)
    ) {
      handlePredefinedProgramClicked("low-traffic");
    }
  }, [autoScale.conditions.length, handlePredefinedProgramClicked, image]);

  useEffect(() => {
    const cpuUsageAbove = autoScale.conditions.find(
      (condition) =>
        condition.ifOption === "cpu-usage" && condition.sideOption === "above"
    );
    const cpuUsageBelow = autoScale.conditions.find(
      (condition) =>
        condition.ifOption === "cpu-usage" && condition.sideOption === "below"
    );

    const ramUsageAbove = autoScale.conditions.find(
      (condition) =>
        condition.ifOption === "ram-usage" && condition.sideOption === "above"
    );
    const ramUsageBelow = autoScale.conditions.find(
      (condition) =>
        condition.ifOption === "ram-usage" && condition.sideOption === "below"
    );

    if (
      cpuUsageAbove &&
      cpuUsageBelow &&
      parseFloat(cpuUsageBelow.prec) >= parseFloat(cpuUsageAbove.prec)
    ) {
      setError(
        intlRef.current.formatMessage(
          { id: "auto-scale.cpu-warning" },
          { value: cpuUsageBelow.prec }
        )
      );
    } else if (
      ramUsageAbove &&
      ramUsageBelow &&
      parseFloat(ramUsageBelow.prec) >= parseFloat(ramUsageAbove.prec)
    ) {
      setError(
        intlRef.current.formatMessage(
          { id: "auto-scale.ram-warning" },
          { value: ramUsageBelow.prec }
        )
      );
    } else {
      setError(null);
    }
  }, [autoScale, setError]);

  function renderThenText(ifOption, sideOption) {
    if (ifOption === "cpu-usage") {
      if (sideOption === "above") {
        return "add cpu cores";
      } else if (sideOption === "below") {
        return "remove cpu cores";
      }
    }

    if (ifOption === "ram-usage") {
      if (sideOption === "above") {
        return "add ram";
      } else if (sideOption === "below") {
        return "remove ram";
      }
    }

    return null;
  }

  function getIfOption(condition) {
    return ifOptions.find((item) => item.value === condition.ifOption);
  }

  function getSideOption(condition) {
    return sideOptions.find((item) => item.value === condition.sideOption);
  }

  function handlePrecChanged(condition, e) {
    condition.prec = e.target.value;
    setAutoScale({ ...autoScale });
  }

  function handleUpscaleLevelChanged(item) {
    autoScale.upscale_level = item.value;
    setAutoScale({ ...autoScale });
  }

  function handleIntervalUpscaleChanged(item) {
    autoScale.interval_upscale = item.value;
    setAutoScale({ ...autoScale });
  }

  function handleIntervalDownscaleChanged(item) {
    autoScale.interval_downscale = item.value;
    setAutoScale({ ...autoScale });
  }

  function handleMaxCpuChanged(e) {
    autoScale.max_resources.cpu = e.target.value;
    setAutoScale({ ...autoScale });
  }

  function handleMaxRamChanged(e) {
    autoScale.max_resources.ram_mb = e.target.value;
    setAutoScale({ ...autoScale });
  }

  function handleTooltipToggle(name) {
    isTooltipOpen[name] = !isTooltipOpen[name];
    setIsTooltipOpen({ ...isTooltipOpen });
  }

  function handleAlertScaleUpChanged(e) {
    autoScale.email_alerts.on_scale_up = e.target.checked;
    setAutoScale({ ...autoScale });
  }

  function handleAlertScaleDownChanged(e) {
    autoScale.email_alerts.on_scale_down = e.target.checked;
    setAutoScale({ ...autoScale });
  }

  function isSelected(type) {
    return (
      (type === "low-traffic" &&
        autoScale.conditions[0]?.prec.toString() === "70" &&
        autoScale.conditions[1]?.prec.toString() === "50" &&
        autoScale.conditions[2]?.prec.toString() === "70" &&
        autoScale.conditions[3]?.prec.toString() === "50" &&
        autoScale.upscale_level === 1) ||
      (type === "medium-traffic" &&
        autoScale.conditions[0]?.prec.toString() === "60" &&
        autoScale.conditions[1]?.prec.toString() === "40" &&
        autoScale.conditions[2]?.prec.toString() === "60" &&
        autoScale.conditions[3]?.prec.toString() === "40" &&
        autoScale.upscale_level === 2) ||
      (type === "high-traffic" &&
        autoScale.conditions[0]?.prec.toString() === "40" &&
        autoScale.conditions[1]?.prec.toString() === "20" &&
        autoScale.conditions[2]?.prec.toString() === "40" &&
        autoScale.conditions[3]?.prec.toString() === "20" &&
        autoScale.upscale_level === 4)
    );
  }

  const maxCpuLabel = useMemo(
    () => user.productPrices.cpu[autoScale.max_resources.cpu].value,
    [autoScale, user]
  );

  const maxRamLabel = useMemo(() => {
    const value = readableBytes(
      user.productPrices.ram_mb[autoScale.max_resources.ram_mb].value *
        Math.pow(1024, 2)
    );

    if (value === "129GB") {
      return "128GB";
    }

    return value;
  }, [autoScale, user]);

  const upscaleLevel = useMemo(
    () =>
      upscaleLevelOptions.find(
        (item) => item.value === autoScale.upscale_level
      ),
    [autoScale.upscale_level]
  );

  const intervalUpscale = useMemo(
    () =>
      intervalUpscaleOptions.find(
        (item) => item.value === autoScale.interval_upscale
      ),
    [autoScale.interval_upscale]
  );

  const intervalDownscale = useMemo(
    () =>
      intervalDownscaleOptions.find(
        (item) => item.value === autoScale.interval_downscale
      ),
    [autoScale.interval_downscale]
  );

  const maxCPU = useMemo(() => {
    const limits = trustPointLimits(user.trust_points);

    if (limits) {
      return user.productPrices.cpu.findIndex(
        (item) => limits.cpu === item.value
      );
    }

    return user.productPrices.cpu.length - 1;
  }, [user]);

  const maxRAM = useMemo(() => {
    const limits = trustPointLimits(user.trust_points);

    if (limits) {
      return user.productPrices.ram_mb.findIndex(
        (item) => limits.ram_mb === item.value
      );
    }

    return user.productPrices.ram_mb.length - 3;
  }, [user]);

  return (
    <div>
      {!image.includes("Windows") && (
        <div className={`${styles.row} ${styles.first}`}>
          <span className={styles.col}>
            <FormattedMessage id="auto-scale.predefined" />
          </span>
          <IconButton
            color="text"
            className={isSelected("low-traffic") ? styles.selected : ""}
            onClick={() => handlePredefinedProgramClicked("low-traffic")}
          >
            <FormattedMessage id="auto-scale.low-traffic" />
          </IconButton>
          <IconButton
            color="text"
            className={isSelected("medium-traffic") ? styles.selected : ""}
            onClick={() => handlePredefinedProgramClicked("medium-traffic")}
          >
            <FormattedMessage id="auto-scale.medium-traffic" />
          </IconButton>
          <IconButton
            color="text"
            className={isSelected("high-traffic") ? styles.selected : ""}
            onClick={() => handlePredefinedProgramClicked("high-traffic")}
          >
            <FormattedMessage id="auto-scale.high-traffic" />
          </IconButton>
          {!isSelected("low-traffic") &&
            !isSelected("medium-traffic") &&
            !isSelected("high-traffic") && (
              <IconButton color="text" className={styles.selected}>
                <FormattedMessage id="auto-scale.custom" />
              </IconButton>
            )}

          <InfoSvg id="tooltip-predefined-program" />
          <Tooltip
            placement="bottom"
            isOpen={isTooltipOpen["predefined-program"]}
            target="tooltip-predefined-program"
            toggle={() => handleTooltipToggle("predefined-program")}
          >
            <div>
              <FormattedMessage id="auto-scale.tooltip.predefined-program" />
            </div>
          </Tooltip>
        </div>
      )}

      {autoScale.conditions.map((condition, key) => (
        <div key={key} className={styles.newWrapper}>
          <span>If</span>
          <span>
            <b>{getIfOption(condition).label}</b>
          </span>
          <span>Is</span>
          <span>
            <b>{getSideOption(condition).label}</b>
          </span>
          <div className={styles.precWrapper}>
            <div>{condition.prec}%</div>
            <input
              type="range"
              value={condition.prec}
              onChange={(e) => handlePrecChanged(condition, e)}
            />
          </div>
          {condition.ifOption && condition.sideOption && (
            <span className="flex1">
              Then{" "}
              {condition.ifOption &&
                condition.sideOption &&
                renderThenText(condition.ifOption, condition.sideOption)}
            </span>
          )}
        </div>
      ))}

      {error && <div className={`error ${styles.error}`}>{error}</div>}

      <div className={styles.intervals}>
        <div>
          <span className={styles.col}>
            <FormattedMessage id="auto-scale.upscale-level" />
            <InfoSvg id="tooltip-upscale-level" />
            <Tooltip
              placement="bottom"
              isOpen={isTooltipOpen["upscale-level"]}
              target="tooltip-upscale-level"
              toggle={() => handleTooltipToggle("upscale-level")}
            >
              <div>
                <FormattedMessage id="auto-scale.tooltip.upscale-level" />
              </div>
            </Tooltip>
          </span>
          <CustomReactSelect
            className={styles.select}
            options={upscaleLevelOptions}
            value={upscaleLevel}
            onChange={(item) => handleUpscaleLevelChanged(item)}
          />
        </div>
        <div>
          <span className={styles.col}>
            <FormattedMessage id="auto-scale.scale-up" />
            <InfoSvg id="tooltip-scale-up" />
            <Tooltip
              placement="bottom"
              isOpen={isTooltipOpen["scale-up"]}
              target="tooltip-scale-up"
              toggle={() => handleTooltipToggle("scale-up")}
            >
              <div>
                <FormattedMessage id="auto-scale.tooltip.scale-up" />
              </div>
            </Tooltip>
          </span>
          <CustomReactSelect
            className={styles.select}
            options={intervalUpscaleOptions}
            value={intervalUpscale}
            onChange={(item) => handleIntervalUpscaleChanged(item)}
          />
        </div>
        <div>
          <span className={styles.col}>
            <FormattedMessage id="auto-scale.cooldown-downscale" />
            <InfoSvg id="tooltip-cooldown-downscale" />
            <Tooltip
              placement="bottom"
              isOpen={isTooltipOpen["cooldown-downscale"]}
              target="tooltip-cooldown-downscale"
              toggle={() => handleTooltipToggle("cooldown-downscale")}
            >
              <div>
                <FormattedMessage id="auto-scale.tooltip.cooldown-downscale" />
              </div>
            </Tooltip>
          </span>
          <CustomReactSelect
            className={styles.select}
            options={intervalDownscaleOptions}
            value={intervalDownscale}
            onChange={(item) => handleIntervalDownscaleChanged(item)}
          />
        </div>
      </div>

      <div className={styles.row}>
        <FormattedMessage id="auto-scale.tip" />
      </div>

      <div className={`${styles.row} ${styles.maxResources}`}>
        <span className={styles.col}>
          <FormattedMessage id="auto-scale.max-cpu" />
        </span>
        <div className={styles.precWrapper}>
          <div>{maxCpuLabel}</div>
          <input
            type="range"
            value={autoScale.max_resources.cpu}
            onChange={handleMaxCpuChanged}
            min={0}
            max={maxCPU}
          />
        </div>
      </div>
      <div className={`${styles.row} ${styles.maxResources}`}>
        <span className={styles.col}>
          <FormattedMessage id="auto-scale.max-ram" />
        </span>
        <div className={styles.precWrapper}>
          <div>{maxRamLabel}</div>
          <input
            type="range"
            value={autoScale.max_resources.ram_mb}
            onChange={handleMaxRamChanged}
            min={0}
            max={maxRAM}
          />
        </div>
      </div>

      <div className={styles.alerts}>
        <div className={styles.first}>
          <Checkbox
            label="auto-scale.email_alerts.on_scale_up"
            checked={autoScale.email_alerts.on_scale_up}
            onChange={handleAlertScaleUpChanged}
          />
        </div>
        <div>
          <Checkbox
            label="auto-scale.email_alerts.on_scale_down"
            checked={autoScale.email_alerts.on_scale_down}
            onChange={handleAlertScaleDownChanged}
          />
        </div>
      </div>
    </div>
  );
}

AutoScale.propTypes = {
  autoScale: PropTypes.object,
  setAutoScale: PropTypes.func,
  image: PropTypes.string,
  error: PropTypes.string,
  setError: PropTypes.func,
};

export default AutoScale;
