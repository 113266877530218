import {
  JOB_SERVER_CLONE,
  JOB_SERVER_CREATE,
  JOB_SERVER_CREATE_FROM_TEMPLATE,
} from "./tasks";

export const WINDOWS10_IMAGE = "Windows-10-64bit";

export const getOsLogo = (vmTemplate) => {
  let osLogo;

  if (!vmTemplate) {
    osLogo = "circle.svg";
  } else if (vmTemplate.toLowerCase().indexOf("windows") > -1) {
    osLogo = "microsoft.png";
  } else if (vmTemplate.toLowerCase().indexOf("ubuntu") > -1) {
    osLogo = "ubuntu.png";
  } else if (vmTemplate.toLowerCase().indexOf("centos") > -1) {
    osLogo = "centos.png";
  } else if (vmTemplate.toLowerCase().indexOf("sophos") > -1) {
    osLogo = "sophos.png";
  } else if (vmTemplate.toLowerCase().indexOf("pfsense") > -1) {
    osLogo = "pfsense.png";
  } else if (vmTemplate.toLowerCase().indexOf("freenas") > -1) {
    osLogo = "freenas.png";
  } else if (vmTemplate.toLowerCase().indexOf("debian") > -1) {
    osLogo = "debian.png";
  } else if (vmTemplate.toLowerCase().indexOf("almalinux") > -1) {
    osLogo = "almalinux.png";
  } else if (vmTemplate.toLowerCase().indexOf("rockylinux") > -1) {
    osLogo = "rockylinux.png";
  } else {
    osLogo = "circle.svg";
  }

  return osLogo;
};

export const getTotalActiveServers = (servers, tasks) => {
  let totalActiveServers = servers
    ? servers.filter((p) => p.status === "Active").length
    : 0;

  if (tasks && tasks.data) {
    totalActiveServers += tasks.data.filter(
      (t) =>
        (t.type === JOB_SERVER_CLONE ||
          t.type === JOB_SERVER_CREATE ||
          t.type === JOB_SERVER_CREATE_FROM_TEMPLATE) &&
        !t.finishedAt &&
        t.step > -1
    ).length;
  }

  return totalActiveServers;
};

export const getImageName = (imageName) => {
  if (!imageName) {
    return null;
  }

  return imageName.includes(":iso/") ? "Custom Image" : imageName;
};
