import styles from "./index.module.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { format, differenceInDays } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ProgressBar,
  CustomText,
  CustomReactSelect,
  Spinner,
} from "@dreamvps/dream-ui/dist";
import {
  useLang,
  useAjax,
  useAlert,
  useUser,
} from "@dreamvps/dream-utils/dist/hooks";
import { ReactComponent as ActivityLogSvg } from "../svgs/activity-log.svg";
import { ReactComponent as RefreshSvg } from "../svgs/refresh.svg";
import { ReactComponent as SupportSvg } from "../svgs/support.svg";
import { ReactComponent as TrashSvg } from "../svgs/trash.svg";
import {
  setActivityLogState,
  setActivityLogScrollTop,
} from "../../store/settings";
import {
  getTypeOfTask,
  JOB_SERVER_CREATE,
  JOB_SERVER_CREATE_FROM_TEMPLATE,
  JOB_MULTI_CHARGE,
} from "../../utils/tasks";
import TasksLogModal from "../modals/task-logs";
import { ReactComponent as CancelSvg } from "../svgs/cancel.svg";
import { SUPER_ADMIN, WHITELABEL } from "@dreamvps/dream-utils/dist/user";
import {
  getColorOfTask,
  getStatusOfTask,
} from "@dreamvps/dream-utils/dist/tasks";

function ActivityLog() {
  const lang = useLang("en");
  const user = useUser();
  const intl = useIntl();
  const dispatch = useDispatch();
  const scrollableRef = useRef();
  const ajax = useAjax();
  const alert = useAlert();
  const router = useHistory();

  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const { isOpen, scrollTop } = useSelector(
    (state) => ({
      isOpen: state.settings.activityLog.isOpen,
      scrollTop: state.settings.activityLog.scrollTop,
    }),
    (prev, next) =>
      prev.isOpen === next.isOpen && prev.scrollTop === next.scrollTop
  );

  const [running, setRunning] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [curSteps, setCurSteps] = useState(0);

  const [mounted, setMounted] = useState(false);
  const [activities, setActivities] = useState({});
  const [isTasksLogModalOpen, setIsTasksLogModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);

  const [serverFilter, setServerFilter] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState(null);
  const [jobTypeFilterOptions, setJobTypeFilterOptions] = useState(null);

  useEffect(() => {
    if (!mounted) {
      scrollableRef.current.scrollTop = scrollTop;

      setMounted(true);
    }
  }, [mounted, scrollTop]);

  useEffect(() => {
    const activities = {};
    let running = 0;
    let totalSteps = 0;
    let curSteps = 0;
    let jobTypeFilterOptionsArr = [];

    if (tasks && tasks.data) {
      tasks.data.forEach((task) => {
        if (!jobTypeFilterOptionsArr.find((item) => item.value === task.type)) {
          jobTypeFilterOptionsArr.push({
            label: getTypeOfTask(task, intl),
            value: task.type,
          });
        }

        if (serverFilter && !task.server?.hostname.includes(serverFilter)) {
          return;
        }
        if (jobTypeFilter?.value && task.type !== jobTypeFilter.value) {
          return;
        }

        task.status = getStatusOfTask(task, intl);

        let dt;
        const diffInDays = differenceInDays(
          new Date(),
          new Date(task.createdAt)
        );
        if (diffInDays === 0) {
          dt = intl.formatMessage({ id: "activity-log.today" });
        } else if (diffInDays === 1) {
          dt = intl.formatMessage({ id: "activity-log.yesterday" });
        } else {
          dt = format(new Date(task.createdAt), "d.M.y");
        }

        activities[dt] = activities[dt] || [];
        activities[dt].push(task);

        if (task.status === -1) {
          running++;

          totalSteps += task.totalSteps;
          curSteps += task.step;
        }
      });
    }

    if (activities.TODAY) {
      activities.TODAY.sort((a) => {
        if (a.status === -1 && a.startedAt) {
          return -1;
        }

        return 1;
      });
    }

    setActivities(activities);
    setRunning(running);
    setTotalSteps(totalSteps);
    setCurSteps(curSteps);

    if (!jobTypeFilterOptions && jobTypeFilterOptionsArr.length > 0) {
      setJobTypeFilterOptions([
        { label: intl.formatMessage({ id: "general.all" }), value: null },
        ...jobTypeFilterOptionsArr,
      ]);
    }
  }, [tasks, intl, serverFilter, jobTypeFilterOptions, jobTypeFilter]);

  function handleButtonClicked() {
    dispatch(setActivityLogState(!isOpen));
  }

  function handleScroll(e) {
    dispatch(setActivityLogScrollTop(e.target.scrollTop));
  }

  async function handleCancelTaskClicked(e, task) {
    e.stopPropagation();

    const data = await ajax(`/jobs/cancel`, {
      jobID: task.id,
    });

    if (data.result === "success") {
      await alert({
        title: intl.formatMessage({ id: "activity-log.cancel-title" }),
        message: intl.formatMessage({ id: "activity-log.cancel-success" }),
        notification: true,
      });
    } else {
      await alert({
        title: intl.formatMessage({ id: "activity-log.cancel-title" }),
        message: intl.formatMessage({ id: "activity-log.cancel-failed" }),
        notification: true,
      });
    }
  }

  function handleTaskRowClicked(task) {
    if (user.role === SUPER_ADMIN || process.env.NODE_ENV === "development") {
      setSelectedTask(task);
      setIsTasksLogModalOpen(true);
    }
  }

  function handleTasksLogModalClosed() {
    setIsTasksLogModalOpen(false);
  }

  async function handleRetryClicked(e, task) {
    e.stopPropagation();

    await ajax("/jobs/retry", { jobID: task.id });
  }

  async function handleSupportClicked(e, task) {
    e.stopPropagation();

    dispatch(setActivityLogState(false));

    router.push(
      `/${lang}/support/open-new-ticket?subject=${encodeURIComponent(
        `Failed job - ${getTypeOfTask(task, intl)}, ${encodeURIComponent(
          `Server: ${task.data.hostname || task.server.hostname}`
        )}`
      )}`
    );
  }

  const activitiesKeys = useMemo(() => Object.keys(activities), [activities]);

  return (
    <>
      <div
        className={`${styles.buttonWrapper} ${isOpen ? styles.isOpen : ""}`}
        onClick={handleButtonClicked}
      >
        <span className={styles.title}>
          <FormattedMessage id="activity-log.title" />
        </span>

        {running > 0 && (
          <CircularProgressbar
            className={styles.circularProgressbar}
            value={(curSteps / totalSteps) * 100}
            strokeWidth={4}
            styles={buildStyles({
              strokeLinecap: "round",
              textSize: "24px",
              pathColor: `rgba(103, 218, 93, ${(curSteps / totalSteps) * 100})`,
              textColor: "#67DA5D",
              trailColor: "rgb(223, 225, 249)",
              backgroundColor: "#3e98c7",
            })}
          />
        )}

        <div className={styles.counterWrapper}>
          {running === 0 && <ActivityLogSvg />}
          {running > 0 && running}
        </div>
      </div>

      <div
        className={`${styles.activityLogWrapper} ${isOpen ? styles.open : ""}`}
      >
        <CancelSvg
          onClick={() => handleButtonClicked()}
          className={styles.close}
        />

        <div
          ref={scrollableRef}
          className={styles.scrollable}
          onScroll={handleScroll}
        >
          <div className={styles.header}>
            <div className={styles.title}>
              <FormattedMessage id="activity-log.title" />
            </div>
            {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
              <div className={styles.filters}>
                <CustomText
                  className={styles.input}
                  placeholder={intl.formatMessage({
                    id: "activity-log.server-filter",
                  })}
                  value={serverFilter}
                  onChange={(e) => setServerFilter(e.target.value)}
                />
                <CustomReactSelect
                  options={jobTypeFilterOptions}
                  value={jobTypeFilter}
                  onChange={(item) => setJobTypeFilter(item)}
                />
              </div>
            )}
          </div>

          {activitiesKeys.length === 0 && (
            <div>
              <FormattedMessage id="general.no-rows" />
            </div>
          )}

          {activitiesKeys.map((k, key) => (
            <div key={key} className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.bold} style={{ width: "125px" }}>
                      {k}
                    </th>
                    <th style={{ width: "190px" }}>
                      <FormattedMessage id="activity-log.server-name" />
                    </th>
                    <th style={{ width: "70px" }}>
                      <FormattedMessage id="activity-log.action" />
                    </th>
                    <th className={styles.startedOn} style={{ width: "100px" }}>
                      <FormattedMessage id="activity-log.started-on" />
                    </th>
                    <th>
                      <FormattedMessage id="activity-log.status" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activities[k].map((task, key) => (
                    <tr
                      key={key}
                      onClick={() => handleTaskRowClicked(task)}
                      className={
                        user.role === SUPER_ADMIN ||
                        process.env.NODE_ENV === "development"
                          ? styles.pointer
                          : ""
                      }
                    >
                      <td className={styles.line}>
                        {task.startedAt && !task.finishedAt && (
                          <div className={styles.progressWrapper}>
                            <ProgressBar
                              current={task.step}
                              max={task.totalSteps}
                            />
                          </div>
                        )}
                        {task.finishedAt && (
                          <div className={styles.time}>
                            {format(new Date(task.finishedAt), "HH:mm")}
                          </div>
                        )}

                        {!task.startedAt && (
                          <div className={styles.time}>
                            <FormattedMessage id="activity-log.in-queue" />
                          </div>
                        )}

                        <div
                          className={`${styles.circle} ${
                            task.status === "Success"
                              ? styles.green
                              : task.status === "Failed"
                              ? styles.red
                              : ""
                          }`}
                        ></div>
                      </td>
                      <td>
                        {task.type !== JOB_MULTI_CHARGE && (
                          <Link
                            href="/[lang]/my-cloud/servers/[id]/overview"
                            to={`/${lang}/my-cloud/servers/${task.serverID}/overview`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {(task.type === JOB_SERVER_CREATE ||
                              task.type === JOB_SERVER_CREATE_FROM_TEMPLATE) &&
                              task.data.hostname}
                            {task.type !== JOB_SERVER_CREATE &&
                              task.type !== JOB_SERVER_CREATE_FROM_TEMPLATE &&
                              task.server?.hostname}
                          </Link>
                        )}
                        {(task.type === JOB_SERVER_CREATE ||
                          task.type === JOB_SERVER_CREATE_FROM_TEMPLATE) &&
                          ` [${task.data.location}]`}

                        {task.data &&
                          task.data.snapshotNameToClone &&
                          ` [${task.data.snapshotNameToClone}]`}
                      </td>
                      <td className={styles.bold}>
                        {getTypeOfTask(task, intl)}
                      </td>
                      <td
                        className={styles.startedOn}
                        dangerouslySetInnerHTML={{
                          __html: `${format(
                            new Date(task.createdAt),
                            "d.M.y"
                          )} ${format(
                            new Date(task.createdAt),
                            "HH:mm:ss"
                          )}<br />By ${task.created_by}`,
                        }}
                      ></td>
                      <td>
                        {task.status === -1 && task.startedAt && <Spinner />}
                        {task.status !== -1 && (
                          <div className={styles.status}>
                            <b style={{ color: getColorOfTask(task.status) }}>
                              {task.status || "A"}
                            </b>
                            {task.status === "Failed" && (
                              <>
                                <RefreshSvg
                                  onClick={(e) => handleRetryClicked(e, task)}
                                />
                                <SupportSvg
                                  className={styles.supportSvg}
                                  onClick={(e) => handleSupportClicked(e, task)}
                                />
                              </>
                            )}
                          </div>
                        )}
                        {!task.startedAt && (
                          <TrashSvg
                            onClick={(e) => handleCancelTaskClicked(e, task)}
                            className={styles.pointer}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>

      <TasksLogModal
        isOpen={isTasksLogModalOpen}
        onClose={handleTasksLogModalClosed}
        taskID={selectedTask.id}
      />
    </>
  );
}

export default ActivityLog;
