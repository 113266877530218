import { createStore, combineReducers } from "redux";
import user from "./user";
import servers from "./servers";
import tasks from "./tasks";
import settings from "./settings";
import notifications from "./notifications";

const rootReducer = combineReducers({
  user,
  servers,
  tasks,
  settings,
  notifications,
});

export default createStore(rootReducer);
