import styles from "./image-templates.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spinner, BasicTable, Box, Radio } from "@dreamvps/dream-ui/dist";
import { FormattedMessage } from "react-intl";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";

function ServerImageTemplates({ image, setImage, location }) {
  const ajax = useAjax();

  const [templates, setTemplates] = useState(null);

  const getTemplates = useCallback(async () => {
    const data = await ajax("/users/getTemplates", { datacenter: location });

    setTemplates(data.templates);
  }, [ajax, location]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  function handleTemplateClicked(template) {
    setImage({
      value: template.image,
      templateID: template._id,
    });
  }

  return (
    <Box className={styles.boxFlex}>
      <BasicTable>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage id="general.template-name" />
            </th>
            <th>
              <FormattedMessage id="general.image" />
            </th>
          </tr>
        </thead>
        <tbody>
          {!templates && (
            <tr>
              <td colSpan={3}>
                <Spinner />
              </td>
            </tr>
          )}

          {templates?.length === 0 && (
            <tr>
              <td colSpan={3}>
                <FormattedMessage id="general.no-rows" />
              </td>
            </tr>
          )}

          {templates?.map((template, key) => (
            <tr
              key={key}
              className="pointer"
              onClick={() => handleTemplateClicked(template)}
            >
              <td style={{ width: "50px" }}>
                <Radio checked={image.templateID === template._id || false} />
              </td>
              <td>{template.hostname}</td>
              <td>{template.image}</td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </Box>
  );
}

ServerImageTemplates.propTypes = {
  image: PropTypes.object,
  setImage: PropTypes.func,
  location: PropTypes.string,
};

export default ServerImageTemplates;
