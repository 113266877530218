export const getTotalSCSI = (config) => {
  return Object.keys(config).reduce(
    (total, item) =>
      total +
      (item.startsWith("scsi") && parseInt(item.replace("scsi", "")) >= 0
        ? 1
        : 0),
    0
  );
};

export const getTotalSata = (config) => {
  return Object.keys(config).reduce(
    (total, item) =>
      total +
      (item.startsWith("sata") && parseInt(item.replace("sata", "")) >= 0
        ? 1
        : 0),
    0
  );
};

export const getTotalNet = (config) => {
  return Object.keys(config).reduce(
    (total, item) =>
      total +
      (item.startsWith("net") && parseInt(item.replace("net", "")) >= 0
        ? 1
        : 0),
    0
  );
};

export const normalizeLanIp = (ip, withX = false) => {
  const ipArr = ip.split(".");

  if (ip.startsWith("17")) {
    return withX
      ? "172.16.x.x"
      : `172.16.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  } else if (ip.startsWith("19")) {
    return withX
      ? "192.168.x.x"
      : `192.168.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  } else if (ip.startsWith("10")) {
    return withX
      ? "10.x.x.x"
      : `10.${ipArr[1] || "0"}.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  }

  return false;
};
