const hosts = {
  "cloud.livevps.co.il": "livevps",
  "cloud.dvs.co.il": "dvs",
  "cloud.punchvps.com": "punchvps",
  "console.strongvps.co.il": "strongvps",
  "fujitsu.packetauth.com": "fujitsu",
};

export const getAllWLDVPS = (whitelabelSettings) => {
  let whitelabels = [
    ...new Set(whitelabelSettings.map((setting) => setting.whitelabel)),
  ];

  return whitelabels;
};

export const getAllWLDVPSForSelect = (whitelabelSettings) => {
  let whitelabels = [
    ...new Set(whitelabelSettings.map((setting) => setting.whitelabel)),
  ];

  return whitelabels.map((wl, idx) => ({
    label: wl || "DreamVPS",
    value: wl,
    key: idx,
  }));
};

export const getHostOfWLDVPS = (wldvps) => {
  const item = Object.keys(hosts).find((key) => hosts[key] === wldvps);

  if (!item) {
    return "dreamvps.com";
  }

  return item.replace("cloud.", "").replace("console.", "");
};

export const getProductionUrlOfWLDVPS = (wldvps) => {
  const item = Object.keys(hosts).find((key) => hosts[key] === wldvps);

  if (!item) {
    return "https://cloud.dreamvps.com";
  }

  return `https://${item}`;
};

export const getServerUrl = () => {
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_SSL === "1") {
      return "https://local.dreamvps.com:3001";
    } else {
      return "http://localhost:3001";
    }
  }

  return hosts[window.location.host]
    ? `https://${window.location.host}/api`
    : "https://cloud.dreamvps.com/api";
};

export const getWebSocketUrl = () => {
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_SSL === "1") {
      return "https://local.dreamvps.com:3001";
    } else {
      return "http://localhost:3001";
    }
  }

  return hosts[window.location.host]
    ? `https://${window.location.host}`
    : "https://cloud.dreamvps.com";
};
