import styles from "./index.module.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useLang } from "@dreamvps/dream-utils/dist/hooks";
import { Box } from "@dreamvps/dream-ui/dist";

export default function ReachedLimit() {
  const lang = useLang("en");

  return (
    <div className={styles.wrapper}>
      <Box>
        <Link
          href="/[lang]/support/open-new-ticket"
          to={`/${lang}/support/open-new-ticket`}
        >
          <FormattedMessage id="create-new-server.reached-limit" />
        </Link>
      </Box>
    </div>
  );
}
