import styles from "./location.module.scss";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as IsraelSvg } from "../svgs/israel.svg";
import { ReactComponent as NetherlandsSvg } from "../svgs/netherlands.svg";
import { ReactComponent as UsaSvg } from "../svgs/usa.svg";
import { ReactComponent as RadioOnSvg } from "../svgs/radio-on.svg";
import { ReactComponent as RadioOffSvg } from "../svgs/radio-off.svg";

function Location({ location, setLocation }) {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setLocation("IL");
    }
  }, [setLocation]);

  return (
    <div className={styles.locationWrapper}>
      <div className={styles.box} onClick={() => setLocation("IL")}>
        <div className={styles.radioWrapper}>
          {location === "IL" ? <RadioOnSvg /> : <RadioOffSvg />}
        </div>
        <div className={styles.imageWrapper}>
          <IsraelSvg />
        </div>
        <div className={styles.country}>Israel</div>
        <div className={styles.city}>Rosh Haayin</div>
      </div>
      <div className={styles.box} onClick={() => setLocation("NL")}>
        <div className={styles.radioWrapper}>
          {location === "NL" ? <RadioOnSvg /> : <RadioOffSvg />}
        </div>
        <div className={styles.imageWrapper}>
          <NetherlandsSvg />
        </div>
        <div className={styles.country}>Netherlands</div>
        <div className={styles.city}>Rotterdam</div>
      </div>
      <div className={styles.box} onClick={() => setLocation("US")}>
        <div className={styles.radioWrapper}>
          {location === "US" ? <RadioOnSvg /> : <RadioOffSvg />}
        </div>
        <div className={styles.imageWrapper}>
          <UsaSvg />
        </div>
        <div className={styles.country}>United States</div>
        <div className={styles.city}>New York</div>
      </div>
    </div>
  );
}

Location.propTypes = {
  location: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setLocation: PropTypes.func,
};

export default Location;
