import React from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "none");

function LayoutWithStripe({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

LayoutWithStripe.propTypes = {
  children: PropTypes.any,
};

export default LayoutWithStripe;
