import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import mainStore from "./store";
import messagesEn from "./translations/en.json";
import messagesHe from "./translations/he.json";
import ErrorBoundary from "./components/error-boundary";
import { getServerUrl } from "./utils/wldvps";
import {
  setAlertModalData,
  setConfirmModalData,
  setPromptModalData,
} from "./store/settings";

import "./styles/minify.css";
import "./styles/globals.css";

const messages = { en: messagesEn, he: messagesHe };

window.serverUrl = getServerUrl();
window.setAlertModalData = setAlertModalData;
window.setConfirmModalData = setConfirmModalData;
window.setPromptModalData = setPromptModalData;

function renderApp() {
  if (process.env.NODE_ENV === "production") {
    return (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    );
  }

  return <App />;
}

ReactDOM.render(
  <>
    <IntlProvider messages={messages.en} locale="en" defaultLocale="en">
      <Provider store={mainStore}>
        <Router>{renderApp()}</Router>
      </Provider>
    </IntlProvider>
  </>,
  document.getElementById("root")
);

reportWebVitals();
