import styles from "./index.module.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import CloudLayout from "../cloud-layout";
import { FormattedMessage } from "react-intl";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleReloadClicked(e) {
    e.preventDefault();

    window.location.reload();
  }

  handleMainClicked(e) {
    e.preventDefault();

    window.location.href = "/";
  }

  render() {
    if (this.state.hasError) {
      return (
        <CloudLayout>
          <div className={styles.wrapper}>
            <div className={styles.title}>
              <FormattedMessage id="error-boundary.title" />
            </div>
            <div className={styles.message}>
              <FormattedMessage
                id="error-boundary.message"
                values={{
                  reload: (val) => (
                    <a href="" onClick={this.handleReloadClicked}>
                      {val}
                    </a>
                  ),
                  main: (val) => (
                    <a href="" onClick={this.handleMainClicked}>
                      {val}
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        </CloudLayout>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
