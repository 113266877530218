import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Fingerprint2 from "fingerprintjs2";
import { useWebSocket } from "./utils/hooks";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";
import {
  setAlertModalData,
  setConfirmModalData,
  setFetchedInitialData,
  setFingerPrint,
  setPromptModalData,
  setReconnecting,
  setWLDVPS,
} from "./store/settings";
import { differenceInMonths } from "date-fns";
import { updateUserDetails } from "./store/user";
import { AlertModal, ConfirmModal, PromptModal } from "@dreamvps/dream-ui/dist";
import ChangeUserPasswordModal from "./components/modals/change-user-password";
import ReconnectingModal from "./components/modals/reconnecting";
import CloudLayout from "./components/cloud-layout";
import LoadingPage from "./components/loading-page";
import CreateNewServer from "./pages/[lang]/my-cloud/create-new-server";
import { getSocket } from "./utils/globals";

const LoginRouter = lazy(() => import("./pages/[lang]/user"));

const MyCloudRouter = lazy(() => import("./pages/[lang]/my-cloud"));

const SupportRouter = lazy(() => import("./pages/[lang]/support"));

const BillingRouter = lazy(() => import("./pages/[lang]/billing"));

const AccountRouter = lazy(() => import("./pages/[lang]/account"));

const ManagementRouter = lazy(() => import("./pages/[lang]/management"));

const ClientsRouter = lazy(() => import("./pages/[lang]/clients"));

const ServicesRouter = lazy(() => import("./pages/[lang]/services"));

let updateLastSeenTimer;

function App() {
  const router = useHistory();
  const dispatch = useDispatch();
  const ajax = useAjax();
  const { connect } = useWebSocket();
  const { fetchedInitialData, reconnecting } = useSelector((state) => ({
    fetchedInitialData: state.settings.fetchedInitialData,
    reconnecting: state.settings.reconnecting,
  }));

  const { alertModalData, confirmModalData, promptModalData } = useSelector(
    (state) => ({
      alertModalData: state.settings.alertModalData,
      confirmModalData: state.settings.confirmModalData,
      promptModalData: state.settings.promptModalData,
    })
  );

  const [isChangeUserPasswordModalOpen, setIsChangeUserPasswordModalOpen] =
    useState(false);

  // function handleRouteChange(url) {
  //   const socket = getSocket();

  //   if (socket) {
  //     socket.emit("update-real-time", { url });
  //   }
  // }

  useEffect(() => {
    function handleMouseMove() {
      if (updateLastSeenTimer) {
        return;
      }

      updateLastSeenTimer = setTimeout(() => {
        const socket = getSocket();

        if (socket) {
          socket.emit("update-real-time", {});
        }

        updateLastSeenTimer = null;
      }, 1000);
    }

    window.document.addEventListener("mousemove", handleMouseMove);

    // router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      window.document.removeEventListener("mousemove", handleMouseMove);

      // router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      });
    } else {
      setTimeout(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      }, 500);
    }
  }, [dispatch]);

  const fetchInitialData = useCallback(async () => {
    if (!router || fetchedInitialData) {
      return;
    }

    try {
      const data = await ajax(`/initialData`);

      if (data.result === "success") {
        if (
          differenceInMonths(
            new Date(),
            new Date(data.passwordLastTimeChanged)
          ) >= 3
        ) {
          setIsChangeUserPasswordModalOpen(true);
        }

        dispatch(updateUserDetails(data));

        connect();

        if (router.location.pathname === "/en/user/login") {
          router.push("/en/my-cloud/servers");
        } else {
          router.push(`${router.location.pathname}${router.location.search}`);
        }

        dispatch(setReconnecting(data.reconnecting));

        return dispatch(setFetchedInitialData(true));
      } else {
        //
      }
    } catch (err) {
      //
    }

    if (
      router.location.pathname !== "/en/user/reset-password" &&
      router.location.pathname !== "/en/user/confirm-password" &&
      router.location.pathname !== "/en/user/email-verification" &&
      router.location.pathname !== "/en/user/forgot-password" &&
      router.location.pathname !== "/en/user/login-sms" &&
      router.location.pathname !== "/en/user/login" &&
      router.location.pathname !== "/en/user/register" &&
      router.location.pathname !== "/en/user/two-factor-authentication"
    ) {
      router.push(
        `/en/user/login?redirect=${encodeURIComponent(
          router.location.pathname
        )}`
      );
    }

    dispatch(setFetchedInitialData(true));
  }, [dispatch, ajax, connect, router, fetchedInitialData]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    dispatch(setWLDVPS(process.env.REACT_APP_WHITELABEL || null));
  }, [dispatch]);

  function handleAlertClosed(closedVia) {
    dispatch(
      setAlertModalData({
        button1: alertModalData.button1,
        button2: alertModalData.button2,
        isOpen: false,
      })
    );
    alertModalData.resolve(closedVia);
  }

  function handleConfirmClosed(closedVia) {
    dispatch(
      setConfirmModalData({
        button1: confirmModalData.button1,
        button2: confirmModalData.button2,
        isOpen: false,
      })
    );
    confirmModalData.resolve(closedVia);
  }

  function handlePromptClosed(closedVia) {
    dispatch(
      setPromptModalData({
        button1: promptModalData.button1,
        button2: promptModalData.button2,
        isOpen: false,
      })
    );
    promptModalData.resolve(closedVia);
  }

  function handleChangeUserPasswordModalClosed() {
    setIsChangeUserPasswordModalOpen(false);
  }

  if (!fetchedInitialData) {
    return <LoadingPage />;
  }

  function renderFallback() {
    if (
      router.location.pathname === "/en/user/reset-password" ||
      router.location.pathname === "/en/user/confirm-password" ||
      router.location.pathname === "/en/user/email-verification" ||
      router.location.pathname === "/en/user/forgot-password" ||
      router.location.pathname === "/en/user/login-sms" ||
      router.location.pathname === "/en/user/login" ||
      router.location.pathname === "/en/user/register" ||
      router.location.pathname === "/en/user/two-factor-authentication"
    ) {
      return null;
    }

    return <CloudLayout></CloudLayout>;
  }

  return (
    <div>
      <Suspense fallback={renderFallback()}>
        <Switch>
          <Route path="/:lang/user">
            <LoginRouter />
          </Route>

          <Route exact path="/:lang/my-cloud/create-new-server">
            <CreateNewServer />
          </Route>

          <Route exact path="/:lang/my-cloud/clone-server/:id">
            <CreateNewServer clone />
          </Route>

          <Route path="/:lang/support">
            <SupportRouter />
          </Route>

          <Route path="/:lang/billing">
            <BillingRouter />
          </Route>

          <Route path="/:lang/account">
            <AccountRouter />
          </Route>

          <Route path="/:lang/management">
            <ManagementRouter />
          </Route>

          <Route path="/:lang/clients">
            <ClientsRouter />
          </Route>

          <Route path="/:lang/services">
            <ServicesRouter />
          </Route>

          <Route
            path={["/", "/:lang", "/:lang/my-cloud", "/:lang/my-cloud/servers"]}
          >
            <CloudLayout>
              <MyCloudRouter />
            </CloudLayout>
          </Route>
        </Switch>
      </Suspense>

      {alertModalData && (
        <AlertModal
          isOpen={alertModalData.isOpen}
          onClose={handleAlertClosed}
          title={alertModalData.title}
          message={alertModalData.message}
          button={alertModalData.button}
          notification={alertModalData.notification}
        />
      )}

      {confirmModalData && (
        <ConfirmModal
          isOpen={confirmModalData.isOpen}
          onClose={handleConfirmClosed}
          title={confirmModalData.title}
          message={confirmModalData.message}
          button1={confirmModalData.button1}
          button2={confirmModalData.button2}
          beforeClose={confirmModalData.beforeClose}
        />
      )}

      {promptModalData && (
        <PromptModal
          isOpen={promptModalData.isOpen}
          onClose={handlePromptClosed}
          title={promptModalData.title}
          message={promptModalData.message}
          defaultText={promptModalData.defaultText}
          button1={promptModalData.button1}
          button2={promptModalData.button2}
          acceptOnlyValue={promptModalData.acceptOnlyValue}
          textType={promptModalData.textType}
          beforeClose={promptModalData.beforeClose}
          placeholder={promptModalData.placeholder}
          withCheckbox={promptModalData.withCheckbox}
        />
      )}

      <ChangeUserPasswordModal
        isOpen={isChangeUserPasswordModalOpen}
        onClose={handleChangeUserPasswordModalClosed}
      />

      <ReconnectingModal isOpen={reconnecting} />
    </div>
  );
}

export default App;
