import styles from "./index.module.scss";

import React from "react";
import { Spinner } from "@dreamvps/dream-ui/dist";

export default function LoadingPage() {
  return (
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  );
}
