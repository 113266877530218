import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "./header";
import Sidebar from "./sidebar";
import { useRoles, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { getFullName } from "@dreamvps/dream-utils/dist/user";
import { Box } from "@dreamvps/dream-ui/dist";
import LockedUser from "../locked-user";

function CloudLayout({ children, allowedCheck = false }) {
  const intl = useIntl();
  const user = useUser();
  const { isAllowed } = useRoles();

  const { activityLog } = useSelector((state) => ({
    activityLog: state.settings.activityLog,
  }));

  function renderNotAllowed() {
    let parentUser;

    if (user && Array.isArray(user.roles)) {
      const usr = user.roles.find(
        (role) => role.parent_user_id === user.current_parent
      );

      if (usr) {
        parentUser = usr.parentUser;
      }
    }

    return (
      <div className={styles.notAllowed}>
        <Box>
          <FormattedMessage
            id="general.not-allowed"
            values={{
              name: parentUser
                ? getFullName(parentUser)
                : intl.formatMessage({
                    id: "general.not-allowed-administrator",
                  }),
            }}
          />
        </Box>
      </div>
    );
  }

  function renderChildren() {
    if (
      typeof user.status === "undefined" ||
      user.status === "USER_STATUS_ACTIVE"
    ) {
      return children;
    }

    return <LockedUser />;
  }

  return (
    <div className={styles.cloudLayout}>
      <Sidebar />
      <div className={styles.childrenWrapper}>
        <Header />
        <div
          className={`${styles.shrink} ${
            activityLog.isOpen ? "styles.sidebarOpen" : ""
          }`}
        >
          {!allowedCheck || isAllowed(allowedCheck)
            ? renderChildren()
            : renderNotAllowed()}
        </div>
      </div>
    </div>
  );
}

CloudLayout.propTypes = {
  children: PropTypes.any,
  allowedCheck: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default CloudLayout;
