export const FUJITSU = "fujitsu";

let socket;

export const getSocket = () => {
  return socket;
};

export const setSocket = (obj) => {
  socket = obj;
};
