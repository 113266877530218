import styles from "./tags-groups.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomText } from "@dreamvps/dream-ui/dist";
import { useWLDVPS } from "../../utils/hooks";
import { useAjax, usePrompt, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { CustomReactSelect } from "@dreamvps/dream-ui/dist";
import {
  getFullName,
  SUPER_ADMIN,
  WHITELABEL,
} from "@dreamvps/dream-utils/dist/user";
import { useMemo } from "react";
import { Checkbox } from "@dreamvps/dream-ui/dist";
import PrivateNetworking from "./private-networking";

function TagsGroups({
  tag,
  setTag,
  group,
  setGroup,
  attachServerToClient,
  setAttachServerToClient,
  selectedFreeDays,
  setSelectedFreeDays,
  showPrivateNetworking,
  setShowPrivateNetworking,
  location,
  vlans,
  setVlans,
  vlanName,
  setVlanName,
  ipScope,
  setIpScope,
  ipAddress,
  setIpAddress,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  setIsNextButtonDisabled,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const prompt = usePrompt();
  const user = useUser();
  const wldvps = useWLDVPS();

  const [gatewayOptions, setGatewayOptions] = useState([]);

  const [groups, setGroups] = useState([]);
  const [allUsers, setAllUsers] = useState();

  const [privateNetworkingError, setPrivateNetworkingError] = useState(false);

  const couponOptions = useMemo(() => {
    const arr = [
      { label: 0, value: 0 },
      { label: "7 days", value: 7 },
      { label: "2 weeks", value: 14 },
      { label: "1 month", value: 30 },
    ];

    if (!wldvps) {
      arr.push(
        ...[
          { label: "2 months", value: 60 },
          { label: "3 months", value: 90 },
        ]
      );
    }

    return arr;
  }, [wldvps]);

  useEffect(() => {
    setSelectedFreeDays(couponOptions[0]);
  }, [setSelectedFreeDays, couponOptions]);

  const getGroups = useCallback(async () => {
    const data = await ajax("/groups/getAll");

    if (data.result === "success") {
      const groups = [
        {
          label: intl.formatMessage({ id: "tags-groups.create-new-group" }),
          value: -1,
        },
        ...data.groups.map((group) => ({
          label: group.name,
          value: group._id,
        })),
      ];

      setGroups(groups);

      return groups;
    }
  }, [ajax, intl]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/users/getAllUsers", {
      filter: { email: 1, firstName: 1, lastName: 1, clientNumber: 1 },
      withMe: true,
    });

    if (data.result === "success") {
      const users = [
        { label: intl.formatMessage({ id: "general.none" }), value: null },
        ...data.users.map((user) => ({
          label: `${user.email} | ${getFullName(user)} | ${user.clientNumber}`,
          value: user._id,
        })),
      ];

      setAllUsers(users);
      setAttachServerToClient(users[0]);
    }
  }, [ajax, intl, setAttachServerToClient]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && allUsers) {
      const user = allUsers.find(
        (user) => user.value === "5ebcfd5949b7e530ddd58262"
      );

      setAttachServerToClient(user);
    }
  }, [allUsers, setAttachServerToClient]);

  useEffect(() => {
    setIsNextButtonDisabled(false);
    setPrivateNetworkingError(false);

    if (
      showPrivateNetworking &&
      [SUPER_ADMIN].includes(user.role) &&
      attachServerToClient?.value
    ) {
      if (!vlanName || !ipAddress || !netmask || !gateway) {
        setIsNextButtonDisabled(true);
        setPrivateNetworkingError(
          intl.formatMessage({ id: "tags-groups.private-networking.error" })
        );
      }
    }
  }, [
    showPrivateNetworking,
    attachServerToClient?.value,
    gateway,
    ipAddress,
    netmask,
    setIsNextButtonDisabled,
    user.role,
    vlanName,
    intl,
  ]);

  async function handleGroupChanged(item) {
    if (item.value === -1) {
      let name = await prompt({
        title: intl.formatMessage({ id: "groups.add.title" }),
        message: intl.formatMessage({ id: "groups.add.content" }),
      });

      name = name ? name.trim() : false;

      if (!name) {
        return setGroup(null);
      }

      await ajax("/groups/create", { name });

      const groups = await getGroups();

      return setGroup(groups.find((group) => group.label === name));
    }

    setGroup(item);
  }

  return (
    <div className={styles.tagsGroupsWrapper}>
      <div>
        <div className={styles.title}>
          <FormattedMessage id="tags-groups.choose-tag-name" />
        </div>
        <div className={styles.container}>
          <div className={styles.text}>
            <FormattedMessage id="tags-groups.choose-tag-name.text" />
          </div>
          <div>
            <CustomText
              placeholder={intl.formatMessage({
                id: "tags-groups.choose-tag-name.type-tags",
              })}
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div>
        <div className={styles.title}>
          <FormattedMessage id="tags-groups.attach-to-group" />
        </div>
        <div className={styles.container}>
          {/* <div className={styles.text}>
            <FormattedMessage id="tags-groups.attach-to-group.text" />
          </div> */}
          <div>
            <CustomReactSelect
              instanceId="attach-to-group"
              options={groups}
              value={group}
              onChange={handleGroupChanged}
            />
          </div>
        </div>
      </div>

      {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
        <div>
          <div className={styles.title}>
            <FormattedMessage id="tags-groups.attach-to-client" />
          </div>
          <div className={styles.container}>
            <div>
              <span className={styles.text}>
                <FormattedMessage id="tags-groups.attach-to-client-description" />
              </span>
              <CustomReactSelect
                instanceId="attach-to-client"
                options={allUsers}
                value={attachServerToClient}
                onChange={(item) => setAttachServerToClient(item)}
              />
            </div>

            {attachServerToClient?.value &&
              (!wldvps || user.allowed_to_give_free_days) && (
                <>
                  <br />
                  <div>
                    <span className={styles.text}>
                      <FormattedMessage id="tags-groups.attach-to-client-coupon" />
                    </span>
                    <CustomReactSelect
                      instanceId="attach-to-client-coupon"
                      options={couponOptions}
                      value={selectedFreeDays}
                      onChange={(item) => setSelectedFreeDays(item)}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      )}

      {[SUPER_ADMIN].includes(user.role) && attachServerToClient?.value && (
        <div className={styles.box}>
          <div
            className={styles.flex}
            onClick={() => setShowPrivateNetworking(!showPrivateNetworking)}
          >
            <div
              className={styles.checkboxWrapper}
              style={{ pointerEvents: "none" }}
            >
              <Checkbox checked={showPrivateNetworking} readOnly />
            </div>
            <div className={styles.infoWrapper}>
              <span className={`${styles.infoTitle} ${styles.normal}`}>
                <FormattedMessage id="additional-services.general-services.private-networking" />
              </span>
            </div>
            <div className={styles.priceFree}>
              <FormattedMessage id="general.free" />
            </div>
          </div>
          {showPrivateNetworking && (
            <div className={styles.flex}>
              <PrivateNetworking
                vlans={vlans}
                setVlans={setVlans}
                location={location}
                vlanName={vlanName}
                setVlanName={setVlanName}
                ipScope={ipScope}
                setIpScope={setIpScope}
                ipAddress={ipAddress}
                setIpAddress={setIpAddress}
                netmask={netmask}
                setNetmask={setNetmask}
                gateway={gateway}
                setGateway={setGateway}
                forUserID={attachServerToClient.value}
                gatewayOptions={gatewayOptions}
                setGatewayOptions={setGatewayOptions}
                setIsNextButtonDisabled={setIsNextButtonDisabled}
              />
            </div>
          )}

          {privateNetworkingError && (
            <div className="error">{privateNetworkingError}</div>
          )}
        </div>
      )}
    </div>
  );
}

TagsGroups.propTypes = {
  tag: PropTypes.string,
  setTag: PropTypes.func,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  attachServerToClient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setAttachServerToClient: PropTypes.func,
  selectedFreeDays: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  setSelectedFreeDays: PropTypes.func,
  showPrivateNetworking: PropTypes.bool,
  setShowPrivateNetworking: PropTypes.func,
  location: PropTypes.string,
  vlans: PropTypes.array,
  setVlans: PropTypes.func,
  vlanName: PropTypes.object,
  setVlanName: PropTypes.func,
  ipScope: PropTypes.object,
  setIpScope: PropTypes.func,
  ipAddress: PropTypes.object,
  setIpAddress: PropTypes.func,
  netmask: PropTypes.object,
  setNetmask: PropTypes.func,
  gateway: PropTypes.object,
  setGateway: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
};

export default TagsGroups;
