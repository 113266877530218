import styles from "./additional-services.module.scss";

import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Tooltip } from "reactstrap";
import useIsMounted from "ismounted";
import isValidDomain from "is-valid-domain";
import {
  Checkbox,
  SimpleBadge,
  Radio,
  IconButton,
} from "@dreamvps/dream-ui/dist";
import PrivateNetworking from "./private-networking";
import UpgradeManagedHostingContent from "../modals/upgrade-managed-hosting/upgrade-managed-hosting-content";
import { SUPER_ADMIN, USER } from "@dreamvps/dream-utils/dist/user";
import { useAjax, usePrompt, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { ReactComponent as HelpSvg } from "../svgs/help.svg";
import WanSelectorModal from "../modals/wan-selector";
import { WINDOWS10_IMAGE } from "../../utils/servers";

function AdditionalServices({
  location,
  image,
  backup,
  setBackup,
  setIsNextButtonDisabled,
  showPrivateNetworking,
  setShowPrivateNetworking,
  vlans,
  setVlans,
  vlanName,
  setVlanName,
  ipScope,
  setIpScope,
  ipAddress,
  setIpAddress,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  publicNetworking,
  setPublicNetworking,
  monthlyBackupPrice,
  priceHourlyBackup,
  paymentType,
  selectedAddons,
  setSelectedAddons,
  customIP,
  setCustomIP,
  rdns,
  setRDNS,

  clone,
}) {
  const intl = useIntl();
  const intlRef = useRef(intl);

  const prompt = usePrompt();
  const promptRef = useRef(prompt);

  const user = useUser();
  const ajax = useAjax();
  const isMounted = useIsMounted();

  const [managedHostings, setManagedHostings] = useState([]);
  const [serverMonitors, setServerMonitors] = useState([]);
  const [isWanSelectorModalOpen, setIsWanSelectorModalOpen] = useState(false);
  const [gatewayOptions, setGatewayOptions] = useState([]);

  const [showRDNS, setShowRDNS] = useState(false);

  const [error, setError] = useState(false);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: ["server-monitor", "managed-hosting"],
    });

    if (!isMounted.current) {
      return;
    }

    setServerMonitors(
      data.products.filter((product) => product.type === "server-monitor")
    );
    setManagedHostings(
      data.products.filter((product) => product.type === "managed-hosting")
    );
  }, [ajax, isMounted]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    setError(false);
    setIsNextButtonDisabled(false);

    if (!publicNetworking && !showPrivateNetworking && user.role === USER) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-networking" })
      );
    }

    if (!showPrivateNetworking) {
      return;
    }

    if (!vlanName || !ipAddress || !netmask || !gateway) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "tags-groups.private-networking.error" })
      );
    }
  }, [
    gateway,
    intl,
    ipAddress,
    netmask,
    publicNetworking,
    setIsNextButtonDisabled,
    showPrivateNetworking,
    user.role,
    vlanName,
  ]);

  useEffect(() => {
    setBackup(image.value !== WINDOWS10_IMAGE);
  }, [image, setBackup]);

  useEffect(() => {
    async function showRdnsPrompt() {
      const recordName = await promptRef.current({
        title: intlRef.current.formatMessage({
          id: "additional-services.rdns.title",
        }),
        message: intlRef.current.formatMessage({
          id: "additional-services.rdns.message",
        }),
        acceptOnlyValue: (text) => ({
          status: isValidDomain(text),
          reason: intlRef.current.formatMessage({
            id: "additional-services.rdns.error-domain",
          }),
        }),
        placeholder: "example.com",
      });

      if (recordName) {
        setRDNS(recordName);
      } else {
        setRDNS(null);
        setShowRDNS(false);
      }
    }

    if (showRDNS) {
      showRdnsPrompt();
    } else {
      setRDNS(null);
      setShowRDNS(false);
    }
  }, [showRDNS, setRDNS]);

  function getPrice(num, toFixed = 2) {
    if (!num || num === 0) {
      return (
        <div className={styles.priceFree}>
          <FormattedMessage id="general.free" />
        </div>
      );
    }

    num = num.toFixed(toFixed);

    return (
      <div
        className={styles.price}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            {
              id: "additional-services.monthly-price",
            },
            { price: `<b>${num}</b>`, currency: "<b>$</b>" }
          ),
        }}
      ></div>
    );
  }

  function handleMonitoringChanged(item) {
    if (!selectedAddons[item._id]) {
      const keys = Object.keys(selectedAddons);

      for (let i = 0; i < keys.length; i++) {
        const serverMonitor = serverMonitors.find((sm) =>
          keys.includes(sm._id)
        );

        if (serverMonitor) {
          delete selectedAddons[serverMonitor._id];
        }
      }
    }

    if (selectedAddons[item._id]) {
      delete selectedAddons[item._id];
    } else {
      selectedAddons[item._id] = item;
    }

    setSelectedAddons({ ...selectedAddons });
  }

  function handleItemTooltipToggle(item) {
    item.isTooltipOpen = !item.isTooltipOpen;
    setServerMonitors([...serverMonitors]);
  }

  function handleCustomIpClicked(e) {
    e.stopPropagation();

    setIsWanSelectorModalOpen(true);
  }

  function handleWanSelectorModalClosed(state) {
    setCustomIP(state);

    setIsWanSelectorModalOpen(false);
  }

  function getMonitoringMessageID(item) {
    const name = item.name.toLowerCase();

    if (name.includes("basic")) {
      return "basic";
    } else if (name.includes("business")) {
      return "business";
    } else {
      return "agency";
    }
  }

  return (
    <div className={styles.additionalServicesWrapper}>
      {image.value !== WINDOWS10_IMAGE && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.backups" />
          </div>
          <div className={styles.box} onClick={() => setBackup(!backup)}>
            <div className={styles.flex}>
              <div
                className={styles.checkboxWrapper}
                style={{ pointerEvents: "none" }}
              >
                <Checkbox checked={backup} readOnly />
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoWrapperSection}>
                  <span className={styles.infoTitle}>
                    <FormattedMessage id="additional-services.backups.title" />
                  </span>
                </div>
                <div className={styles.infoContent}>
                  <FormattedMessage id="additional-services.backups.content" />
                </div>
              </div>
              {priceHourlyBackup || monthlyBackupPrice ? (
                getPrice(
                  paymentType === "hourly"
                    ? priceHourlyBackup
                    : monthlyBackupPrice,
                  3
                )
              ) : (
                <SimpleBadge className={styles.simpleBadgeWrapper}>
                  <FormattedMessage id="general.recommended" />
                </SimpleBadge>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <FormattedMessage id="additional-services.general-services" />
        </div>

        <div
          className={styles.box}
          onClick={() => setPublicNetworking(!publicNetworking)}
        >
          <div className={styles.flex}>
            <div
              className={styles.checkboxWrapper}
              style={{ pointerEvents: "none" }}
            >
              <Checkbox checked={publicNetworking} readOnly />
            </div>
            <div className={`${styles.infoWrapper} ${styles.withButton}`}>
              <span className={`${styles.infoTitle} ${styles.normal}`}>
                <FormattedMessage id="additional-services.general-services.public-networking" />
              </span>

              {publicNetworking && [SUPER_ADMIN].includes(user.role) && !clone && (
                <IconButton
                  color="light-purple"
                  onClick={handleCustomIpClicked}
                >
                  {`${intl.formatMessage({
                    id: "additional-services.general-services.custom-ip",
                  })} - ${
                    customIP || intl.formatMessage({ id: "general.none" })
                  }`}
                </IconButton>
              )}
            </div>
          </div>
        </div>

        {user.role !== SUPER_ADMIN && (
          <div
            className={styles.box}
            onClick={() => setShowPrivateNetworking(!showPrivateNetworking)}
          >
            <div className={styles.flex}>
              <div
                className={styles.checkboxWrapper}
                style={{ pointerEvents: "none" }}
              >
                <Checkbox checked={showPrivateNetworking} readOnly />
              </div>
              <div className={styles.infoWrapper}>
                <span className={`${styles.infoTitle} ${styles.normal}`}>
                  <FormattedMessage id="additional-services.general-services.private-networking" />
                </span>
              </div>
              {getPrice()}
            </div>
            {showPrivateNetworking && (
              <div className={styles.flex}>
                <PrivateNetworking
                  vlans={vlans}
                  setVlans={setVlans}
                  location={location}
                  vlanName={vlanName}
                  setVlanName={setVlanName}
                  ipScope={ipScope}
                  setIpScope={setIpScope}
                  ipAddress={ipAddress}
                  setIpAddress={setIpAddress}
                  netmask={netmask}
                  setNetmask={setNetmask}
                  gateway={gateway}
                  setGateway={setGateway}
                  gatewayOptions={gatewayOptions}
                  setGatewayOptions={setGatewayOptions}
                />
              </div>
            )}
          </div>
        )}

        <div className={styles.box} onClick={() => setShowRDNS(!showRDNS)}>
          <div className={styles.flex}>
            <div
              className={styles.checkboxWrapper}
              style={{ pointerEvents: "none" }}
            >
              <Checkbox checked={showRDNS} readOnly />
            </div>
            <div className={styles.infoWrapper}>
              <span className={`${styles.infoTitle} ${styles.normal}`}>
                <FormattedMessage id="additional-services.general-services.rdns" />
              </span>
            </div>
            {rdns}
          </div>
        </div>

        {error && <div className="error">{error}</div>}
      </div>

      {/* {serverMonitors.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.general-services.monitoring" />
          </div>
          {serverMonitors.map((item, key) => (
            <div
              key={key}
              className={styles.box}
              onClick={() => handleMonitoringChanged(item)}
            >
              <div className={styles.flex}>
                <div className={styles.checkboxWrapper}>
                  <Radio
                    value={item._id}
                    checked={!!selectedAddons[item._id]}
                    onClick={() => handleMonitoringChanged(item)}
                  />
                </div>
                <div className={styles.infoWrapper}>
                  <div className={styles.infoWrapperSection}>
                    <span className={styles.infoTitle}>
                      <FormattedMessage
                        id={`additional-services.general-services.monitoring.${getMonitoringMessageID(
                          item
                        )}.title`}
                      />

                      {item.tooltip_info && (
                        <>
                          <div id={`tooltip-monitor-${item._id}`}>
                            <div>
                              <HelpSvg />
                            </div>
                          </div>

                          <Tooltip
                            placement="bottom"
                            isOpen={item.isTooltipOpen}
                            target={`tooltip-monitor-${item._id}`}
                            toggle={() => handleItemTooltipToggle(item)}
                          >
                            {item.tooltip_info}
                          </Tooltip>
                        </>
                      )}
                    </span>
                  </div>
                  <div className={styles.infoContent}>
                    <FormattedMessage
                      id={`additional-services.general-services.monitoring.${getMonitoringMessageID(
                        item
                      )}.content`}
                    />
                  </div>
                </div>
                {getPrice(item.price)}
              </div>
            </div>
          ))}
        </div>
      )} */}

      {managedHostings.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.managed-hosting" />
          </div>

          <div className={styles.box}>
            <div className={styles.flex}>
              <UpgradeManagedHostingContent
                managedHostings={managedHostings}
                selectedAddons={selectedAddons}
                setSelectedAddons={setSelectedAddons}
              />
            </div>
          </div>
        </div>
      )}

      <WanSelectorModal
        isOpen={isWanSelectorModalOpen}
        onClose={handleWanSelectorModalClosed}
        location={location}
      />
    </div>
  );
}

AdditionalServices.propTypes = {
  location: PropTypes.string,
  image: PropTypes.object,
  backup: PropTypes.bool,
  setBackup: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
  showPrivateNetworking: PropTypes.bool,
  setShowPrivateNetworking: PropTypes.func,
  vlans: PropTypes.array,
  setVlans: PropTypes.func,
  vlanName: PropTypes.object,
  setVlanName: PropTypes.func,
  ipScope: PropTypes.object,
  setIpScope: PropTypes.func,
  ipAddress: PropTypes.object,
  setIpAddress: PropTypes.func,
  netmask: PropTypes.object,
  setNetmask: PropTypes.func,
  gateway: PropTypes.object,
  setGateway: PropTypes.func,
  publicNetworking: PropTypes.bool,
  setPublicNetworking: PropTypes.func,
  monthlyBackupPrice: PropTypes.number,
  priceHourlyBackup: PropTypes.number,
  paymentType: PropTypes.string,
  selectedAddons: PropTypes.object,
  setSelectedAddons: PropTypes.func,
  customIP: PropTypes.string,
  setCustomIP: PropTypes.func,
  rdns: PropTypes.string,
  setRDNS: PropTypes.func,

  clone: PropTypes.bool,
};

export default AdditionalServices;
