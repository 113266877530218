import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton } from "@dreamvps/dream-ui/dist";
import { CustomText } from "@dreamvps/dream-ui/dist";
import { useAjax } from "@dreamvps/dream-utils/dist/hooks";

function UploadIsoModal({ isOpen, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();

  const [url, setUrl] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleUploadClicked() {
    const _url = url.trim();

    setError(false);

    if (!_url) {
      return setError(
        intl.formatMessage({ id: "upload-iso-modal.error.empty-url" })
      );
    }

    const urlToCheck = _url.split(/[?#]/)[0];

    if (!urlToCheck.endsWith(".iso")) {
      return setError(
        intl.formatMessage({ id: "upload-iso-modal.error.wrong-url" })
      );
    }

    setLoading(true);
    ajax("/tickets/createTicket", {
      department: "2",
      subject: "New ISO request",
      content: `I kindly request you to upload this ISO:<br /><b>${url}</b>`,
      relatedService: null,
      priority: "2",
      userIDToAttach: false,
      assignToUser: null,
      reminder: null,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="upload-iso-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <FormattedMessage id="upload-iso-modal.description" />
        </div>

        <div className={styles.text}>
          <CustomText value={url} onChange={(e) => setUrl(e.target.value)} />
        </div>

        <div>
          <FormattedMessage id="upload-iso-modal.description2" />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleUploadClicked}
        >
          <FormattedMessage id="upload-iso-modal.request-iso" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

UploadIsoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UploadIsoModal;
