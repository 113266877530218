import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReconnecting, setTotalServers } from "../store/settings";
import { useCallback, useRef, useEffect, useState } from "react";
import { useAjax, useUser, useLogout } from "@dreamvps/dream-utils/dist/hooks";
import { updateServers } from "../store/servers";
import { updateUserDetails } from "../store/user";
import { SUPER_ADMIN, WHITELABEL } from "@dreamvps/dream-utils/dist/user";
import { getTotalActiveServers } from "./servers";
import { addSeconds } from "date-fns";
import { getHostOfWLDVPS, getWebSocketUrl } from "./wldvps";
import { UPDATE_TASKS } from "../store/tasks";
import { SET_NOTIFICATIONS } from "../store/notifications";
import { FUJITSU, getSocket, setSocket } from "./globals";

const serversCache = {};

export const useServer = () => {
  const { id } = useParams();
  const socket = getSocket();

  const ajax = useAjax();
  const ajaxRef = useRef(ajax);

  const [server, setServer] = useState(null);

  const isUnmounted = useRef(false);

  const getServer = useCallback(
    async (serverObject = null) => {
      if (!id || isUnmounted.current) {
        return;
      }

      let data;

      if (serverObject && serverObject._id === id) {
        setServer(serverObject);
      } else if (serversCache[id] && new Date() <= serversCache[id].expire) {
        setServer(serversCache[id].server);
      } else {
        data = await ajaxRef.current("/servers/get", {
          serverID: id,
        });

        if (data.server?.rdns) {
          Object.keys(data.server.rdns).forEach((key) => {
            data.server.rdns[key.replace(/_/g, ".")] = data.server.rdns[key];
          });
        }

        serversCache[id] = {
          server: data.server,
          expire: addSeconds(new Date(), 1),
        };

        setServer(data.server);
      }
    },
    [id]
  );

  const handleServerUpdated = useCallback(
    (data) => {
      getServer(data.serverObj);
    },
    [getServer]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("update-server", handleServerUpdated);

    getServer();

    return () => {
      isUnmounted.current = true;

      socket.off("update-server", handleServerUpdated);
    };
  }, [getServer, socket, handleServerUpdated]);

  return server;
};

export const useServerStatus = (serverID) => {
  const ajax = useAjax();

  const [serverStatus, setServerStatus] = useState(null);

  const socketListen = useCallback(async () => {
    if (!serverID) {
      return;
    }

    await ajax("/socket/listen", {
      method: "server-status",
      params: { serverID },
    });
  }, [ajax, serverID]);

  const socketStop = useCallback(async () => {
    if (!serverID) {
      return;
    }

    await ajax("/socket/stop", {
      method: "server-status",
      params: { serverID },
    });
  }, [ajax, serverID]);

  useEffect(() => {
    if (!serverID) {
      return;
    }

    const socket = getSocket();

    socketListen(serverID);

    socket.on("server-status", (data) => {
      if (!data.status) {
        return;
      }

      if (serverID === data.serverID) {
        data.status.proxmox_live_netin = data.proxmox_live.proxmox_live_netin;
        data.status.proxmox_live_netout = data.proxmox_live.proxmox_live_netout;
        data.status.proxmox_live_netin_before =
          data.proxmox_live.proxmox_live_netin_before;
        data.status.proxmox_live_netout_before =
          data.proxmox_live.proxmox_live_netout_before;
        data.status.proxmox_live_net_time =
          data.proxmox_live.proxmox_live_net_time;
        data.status.proxmox_live_net_time_before =
          data.proxmox_live.proxmox_live_net_time_before;

        setServerStatus(data.status);
      }
    });

    return () => {
      socketStop(serverID);

      socket.removeAllListeners("server-status");
    };
  }, [serverID, socketListen, socketStop]);

  return { serverStatus };
};

export const useServers = () => {
  const { servers } = useSelector(
    (state) => ({
      servers: state.servers,
    }),
    (prev, next) =>
      JSON.stringify(prev.servers) === JSON.stringify(next.servers)
  );

  return servers;
};

export const useUrls = () => {
  const wldvps = useWLDVPS();

  const url = getHostOfWLDVPS(wldvps);

  return {
    IL_CONSOLE_URL: `https://ilconsole.${url}`,
    NL_CONSOLE_URL: `https://nlconsole.${url}`,
    US_CONSOLE_URL: `https://usconsole.${url}`,
  };
};

export const useInitialDataFetching = () => {
  const ajax = useAjax();
  const dispatch = useDispatch();
  const { connect } = useWebSocket();

  return async (connectToSocket = false) => {
    const data = await ajax(`/initialData`);

    if (data.result === "success") {
      dispatch(updateUserDetails(data));

      if (connectToSocket) {
        connect();
      }

      return true;
    }

    return false;
  };
};

export const useLimits = () => {
  const user = useUser();
  const servers = useServers();

  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const [serversLimit, setServersLimit] = useState(false);

  useEffect(() => {
    if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
      setServersLimit(Number.MAX_SAFE_INTEGER);
    } else if (servers) {
      setServersLimit(
        user.serversLimit - getTotalActiveServers(servers, tasks)
      );
    }
  }, [servers, tasks, user.serversLimit, user.role]);

  return { serversLimit };
};

export const useWLDVPS = () => {
  const { wldvps } = useSelector((state) => ({
    wldvps: state.settings.wldvps,
  }));

  return wldvps;
};

export const useDesignTemplate = () => {
  if (process.env.REACT_APP_DESIGN_TEMPLATE === "fujitsu") {
    return FUJITSU;
  }

  return null;
};

export const useWebSocket = () => {
  const dispatch = useDispatch();
  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const logout = useLogout("en");
  const logoutRef = useRef(logout);

  let timerID = useRef();
  let lastNotificationID = useRef();

  const connect = useCallback(() => {
    const curSocket = getSocket();

    if (curSocket) {
      curSocket.removeAllListeners("servers-update");
      curSocket.removeAllListeners("tasks-update");
      curSocket.removeAllListeners("auto-logout");
      curSocket.disconnect(true);
    }

    const socket = io(getWebSocketUrl(), {
      withCredentials: true,
    });

    socket.on("servers-update", (data) => {
      dispatch(updateServers(data.servers));
      dispatch(setTotalServers(data.totalServers));
    });

    socket.on("tasks-update", (data) => {
      data.tasks.forEach((task) => {
        if (task.custom_prec > 0) {
          task.step = task.custom_prec;
          task.totalSteps = 100;
        }
      });

      dispatch({
        type: UPDATE_TASKS,
        payload: { data: data.tasks, lastRequest: +new Date() },
      });

      if (
        data.notifications &&
        data.notifications[0] &&
        lastNotificationID.current !== data.notifications[0]._id
      ) {
        lastNotificationID.current = data.notifications[0]._id;

        dispatch({
          type: SET_NOTIFICATIONS,
          payload: data.notifications,
        });
      }
    });

    socket.on("auto-logout", () => {
      logoutRef.current();
    });

    socket.on("maintenance", () => {
      window.location.href = "/";
    });

    socket.on("reconnecting", (data) => {
      dispatch(setReconnecting(data.state));
    });

    setSocket(socket);
  }, [dispatch]);

  useEffect(() => {
    if (!serversFetching) {
      return;
    }

    const socket = getSocket();

    if (socket) {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }

      timerID.current = setTimeout(() => {
        socket.emit("update-filters", {
          page: serversFetching.page,
          maxServersToShow: serversFetching.maxServersToShow,
          totalServers: serversFetching.totalServers,
          filter: serversFetching.filter,
          showOnly: serversFetching.showOnly,
          sortBy: serversFetching.sortBy,
          sortDirection: serversFetching.sortDirection,
          group: serversFetching.group,
        });
      }, 100);
    }
  }, [serversFetching]);

  return { connect };
};
