import styles from "./image.module.scss";

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import useIsMounted from "ismounted";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ReactComponent as RadioOnSvg } from "../svgs/radio-on.svg";
import { ReactComponent as RadioOffSvg } from "../svgs/radio-off.svg";
import { Navigator } from "@dreamvps/dream-ui/dist";
import { SUPER_ADMIN, isSuperAdmin } from "@dreamvps/dream-utils/dist/user";
import { useAjax, useAlert, useUser } from "@dreamvps/dream-utils/dist/hooks";
import { Box } from "@dreamvps/dream-ui/dist";
import { Spinner } from "@dreamvps/dream-ui/dist";
import { IconButton } from "@dreamvps/dream-ui/dist";
import UploadIsoModal from "../modals/upload-iso";
import { WINDOWS10_IMAGE } from "../../utils/servers";
import { CustomReactSelect } from "@dreamvps/dream-ui/dist";
import ServerImageTemplates from "./image-templates";

function ServerImage({
  image,
  prevImage,
  setImage,
  selectedAddons,
  setSelectedAddons,
  showCustomIso = true,
  setCpu,
  setRamMB,
  setSsdGB,
  setAdditionalSsdGb,
  setBackup,
  location,
  currentImage = null,
  clone = false,
  onlyAutoScale = false,
}) {
  const user = useUser();
  const ajax = useAjax();
  const isMounted = useIsMounted();

  const alert = useAlert();
  const alertRef = useRef(alert);

  const intl = useIntl();
  const intlRef = useRef(intl);

  const [selectedPlatform, setSelectedPlatform] = useState("server");
  const [images, setImages] = useState({});
  const [customIsoList, setCustomIsoList] = useState(null);
  const [windowsDesktopLicense, setWindowsDesktopLicense] = useState(null);
  const [windowsServerLicense, setWindowsServerLicense] = useState(null);

  const [iso, setISO] = useState(null);
  const [isUploadIsoModalOpen, setIsUploadIsoModalOpen] = useState(false);

  const menuItems = useMemo(() => {
    const items = [
      {
        title: "Server OS",
        selected: selectedPlatform === "server",
        onClick: () => setSelectedPlatform("server"),
      },
      {
        title: "Desktop OS",
        selected: selectedPlatform === "desktop",
        onClick: () => setSelectedPlatform("desktop"),
      },
    ];

    if (showCustomIso) {
      items.push({
        title: "Custom ISO",
        selected: selectedPlatform === "custom-iso",
        onClick: () => setSelectedPlatform("custom-iso"),
      });
    }

    items.push({
      title: intlRef.current.formatMessage({
        id: "server-settings-layout.templates",
      }),
      selected: selectedPlatform === "templates",
      onClick: () => setSelectedPlatform("templates"),
    });

    return items;
  }, [selectedPlatform, showCustomIso]);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: ["windows-license"],
    });

    if (!isMounted.current) {
      return;
    }

    const windowsDesktopLicense = data.products.find(
      (product) =>
        product.type === "windows-license" &&
        product.name.toLowerCase().includes("desktop")
    );

    const windowsServerLicense = data.products.find(
      (product) =>
        product.type === "windows-license" &&
        product.name.toLowerCase().includes("server")
    );

    setWindowsDesktopLicense(windowsDesktopLicense);
    setWindowsServerLicense(windowsServerLicense);
  }, [ajax, isMounted]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    const images = {};

    user.productPrices.image.forEach((img) => {
      if (
        img.enable &&
        (!img.only_for_admin || isSuperAdmin(user)) &&
        (!onlyAutoScale || img.support_autoscale)
      ) {
        images[img.platform] = images[img.platform] || {};
        images[img.platform][img.os] = images[img.platform][img.os] || [];

        if (img.value !== currentImage) {
          images[img.platform][img.os].push(img);
        }
      }
    });

    setImages(images);
  }, [user, currentImage, onlyAutoScale]);

  const getCustomIsoList = useCallback(async () => {
    const data = await ajax("/proxmox/nodes/storage/getCustomIsoList", {
      dataCenter: location,
    });

    if (data.result === "error") {
      setCustomIsoList([]);
      return alertRef.current({
        title: intlRef.current.formatMessage({ id: "general.error" }),
        message: intlRef.current.formatMessage({ id: "general.general-error" }),
      });
    }

    if (!isMounted.current) {
      return;
    }

    setCustomIsoList(data.isoList);
  }, [ajax, isMounted, location]);

  useEffect(() => {
    if (selectedPlatform === "custom-iso") {
      getCustomIsoList();
    }
  }, [getCustomIsoList, selectedPlatform]);

  const handleImageChanged = useCallback(
    (img, resetAddons = false) => {
      let newSelectedAddons = { ...selectedAddons };

      if (resetAddons) {
        newSelectedAddons = {};
      }

      if (windowsDesktopLicense) {
        delete newSelectedAddons[windowsDesktopLicense._id];
      }

      if (windowsServerLicense) {
        delete newSelectedAddons[windowsServerLicense._id];
      }

      if (img.os === "Windows") {
        if (img.platform === "desktop" && windowsDesktopLicense) {
          newSelectedAddons[windowsDesktopLicense._id] = windowsDesktopLicense;
        } else if (img.platform === "server" && windowsServerLicense) {
          newSelectedAddons[windowsServerLicense._id] = windowsServerLicense;
        }
      }

      setSelectedAddons(newSelectedAddons);

      setImage(img);
    },
    [
      selectedAddons,
      setImage,
      setSelectedAddons,
      windowsDesktopLicense,
      windowsServerLicense,
    ]
  );

  useEffect(() => {
    if (image?._id !== prevImage?._id) {
      setCpu(1);
      setRamMB(1024);
      setSsdGB(20);
      setAdditionalSsdGb(0);

      if (image) {
        handleImageChanged(image, true);
      }
    }
  }, [
    image,
    setAdditionalSsdGb,
    setCpu,
    setRamMB,
    setSsdGB,
    prevImage,
    setSelectedAddons,
    handleImageChanged,
  ]);

  useEffect(() => {
    setBackup(image?.value !== WINDOWS10_IMAGE);
  }, [image, setBackup]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && images.server && !clone) {
      setImage(images.server.Ubuntu[0]);
    }
  }, [setImage, images.server, clone]);

  function handleDropdownToggle(item) {
    item.isDropdownOpen = !item.isDropdownOpen;
    setImages({ ...images });
  }

  function handleCustomIsoChanged(item) {
    setISO(item);
    setImage({ value: item.value.volid, os: item.value.volid, custom: true });
  }

  function handleUploadIsoClicked() {
    setIsUploadIsoModalOpen(true);
  }

  async function handleUploadIsoModalClosed(state) {
    setIsUploadIsoModalOpen(false);

    if (state) {
      await alert({
        title: intl.formatMessage({ id: "general.upload-iso" }),
        message: intl.formatMessage({ id: "general.upload-iso.success" }),
        notification: true,
      });
    }
  }

  const customIsoListOptions = useMemo(
    () =>
      customIsoList
        ? customIsoList
            .filter(
              (iso) =>
                (user.role === SUPER_ADMIN && iso.enabled_in_admin) ||
                (user.role !== SUPER_ADMIN && iso.enabled_in_user)
            )
            .map((iso) => ({
              label: `${iso.custom_name}${
                iso.description ? ` - ${iso.description}` : ""
              }`,
              value: iso,
            }))
        : [],
    [customIsoList, user.role]
  );

  return (
    <div className={styles.container}>
      <div className={styles.navigatorWrapper}>
        <Navigator items={menuItems} />
      </div>

      <div className={styles.imagesWrapper}>
        {selectedPlatform === "custom-iso" && (
          <Box className={styles.boxFlex}>
            {!customIsoList && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}

            {customIsoList && (
              <div className={styles.row}>
                <CustomReactSelect
                  options={customIsoListOptions}
                  value={iso}
                  onChange={handleCustomIsoChanged}
                />
              </div>
            )}

            <div className={styles.header}>
              <div className={styles.text}>
                <FormattedMessage id="general.upload-iso-cant-find" />
              </div>

              <IconButton color="light-purple" onClick={handleUploadIsoClicked}>
                <FormattedMessage id="general.upload-iso" />
              </IconButton>
            </div>
          </Box>
        )}

        {selectedPlatform === "templates" && (
          <ServerImageTemplates
            image={image}
            setImage={setImage}
            location={location}
          />
        )}

        {selectedPlatform !== "custom-iso" &&
          images[selectedPlatform] &&
          Object.keys(images[selectedPlatform]).map((os, key) => (
            <div
              key={key}
              className={styles.box}
              onClick={() => handleDropdownToggle(images[selectedPlatform][os])}
            >
              <div className={styles.radioWrapper}>
                {image &&
                image.platform === selectedPlatform &&
                image.os === os ? (
                  <RadioOnSvg />
                ) : (
                  <RadioOffSvg />
                )}
              </div>
              <div className={styles.imageWrapper}>
                <img src={`/img/${os.toLowerCase()}.png`} />
              </div>
              <div className={styles.image}>{os}</div>
              <div
                className={styles.versionSelector}
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown
                  isOpen={images[selectedPlatform][os].isDropdownOpen}
                  toggle={() =>
                    handleDropdownToggle(images[selectedPlatform][os])
                  }
                >
                  <DropdownToggle nav>
                    {image &&
                    image.platform === selectedPlatform &&
                    image.os === os ? (
                      <span className={styles.selected}>{image.version}</span>
                    ) : (
                      <FormattedMessage id="general.select" />
                    )}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {images[selectedPlatform][os].map((img, key) => (
                      <DropdownItem
                        key={key}
                        onClick={() => handleImageChanged(img)}
                      >
                        {img.version}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ))}
      </div>

      <UploadIsoModal
        isOpen={isUploadIsoModalOpen}
        onClose={handleUploadIsoModalClosed}
      />
    </div>
  );
}

ServerImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  prevImage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setImage: PropTypes.func,
  selectedAddons: PropTypes.object,
  setSelectedAddons: PropTypes.func,
  showCustomIso: PropTypes.bool,
  setCpu: PropTypes.func,
  setRamMB: PropTypes.func,
  setSsdGB: PropTypes.func,
  setAdditionalSsdGb: PropTypes.func,
  setBackup: PropTypes.func,
  currentImage: PropTypes.string,
  clone: PropTypes.bool,
  onlyAutoScale: PropTypes.bool,
  location: PropTypes.string,
};

export default ServerImage;
