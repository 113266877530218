import styles from "./index.module.scss";

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import generatePassword from "password-generator";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton } from "@dreamvps/dream-ui/dist";
import { ReactComponent as CopySvg } from "../../svgs/copy.svg";
import { ReactComponent as RefreshSvg } from "../../svgs/refresh.svg";
import { ReactComponent as CheckSvg } from "../../svgs/check.svg";
import { selectText } from "@dreamvps/dream-utils/dist";

function GeneratePasswordModal({ isOpen, onClose }) {
  const passwordRef = useRef();

  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    handleGenerateClicked();
  }, []);

  function handleGenerateClicked() {
    let password;

    do {
      password = generatePassword(16, false, /([A-Za-z\d?-])/g);

      if (
        /\d/.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password)
      ) {
        break;
      }
    } while (true);

    setGeneratedPassword(password);

    if (passwordRef.current) {
      selectText(passwordRef.current);
    }
  }

  function handleCopyToClipboardClicked() {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <Modal
      className={styles.generatePasswordModal}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="generate-password-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.passwordBox}>
          <span ref={passwordRef}>{generatedPassword}</span>

          {!copied && (
            <CopyToClipboard
              text={generatedPassword}
              onCopy={handleCopyToClipboardClicked}
            >
              <CopySvg />
            </CopyToClipboard>
          )}

          {copied && <CheckSvg />}

          <RefreshSvg onClick={handleGenerateClicked} />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={() => onClose(generatedPassword)}>
          <FormattedMessage id="generate-password-modal.action" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

GeneratePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GeneratePasswordModal;
